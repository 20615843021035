<template>
  <div class="eva-item-select-component">
    <eva-command-list
        type="icon-text--secondary--large"
        column
        :commands="commands"
    />
  </div>
</template>

<script>
export default {
  name: 'eva-item-select-component',

  props: {
    settings: {
      typo: Object
    },
    box: {
      type: Object
    }
  },

  data() {
    return {
      commands: null
    }
  },

  async mounted() {
    let items = this.settings && this.settings.items;
    let labelName = this.settings && this.settings.label || 'label';
    if (typeof items === 'string') {
      let commands = await this.$eva.$http.getList(items);
      this.commands = this.$eva.$commands.createList(
          commands.items.map((command) => ({
            name: command.sys_name,
            icon: command.params ? command.params.icon : '',
            label: typeof command.name === 'object' ? command.name.ru : command.name,
            color: 'primary',
            handle: () => {
              if (command.handle) {
                command.handle();
              }
              this.box.hide(command)
            }
          }))
      );
      if (this.commands.length === 1) {
        this.commands[0].execute();
      }
    } else if (Array.isArray(items)) {
      this.commands = this.$eva.$commands.createList(
          items.map((command) => ({
            name: command.label,
            icon: command.params ? command.params.icon : this.settings.icon,
            label: typeof command.label === 'object' ? command.name.ru : command[labelName],
            color: 'primary',
            handle: () => {
              if (command.handle) {
                command.handle();
              }
              this.box.hide(command)
            }
          }))
      );
    }
  }
}
</script>

<style lang="less">
.eva-item-select-component {
  padding: (@eva-padding * 2);
  max-height: 400px;
  .eva-btn--secondary {
    /*background-color: #F6F8FF;
    border-color: #F6F8FF;*/
    justify-content: start;
    flex-grow: 1;
    &:hover {
      /*background-color: #E5F1FF;
      border-color: #E5F1FF;*/
    }
  }
}
</style>
