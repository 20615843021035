import Vue from "vue";
import EvaPlugin from "@eva/client/plugins/EvaPlugin";
import EvaRouteManager from "./EvaRouteManager";
import { reactive } from "vue";

Vue.config.devtools = true;
Vue.config.productionTip = false;

import 'vuetify/dist/vuetify.min.css';
import '@mdi/font/css/materialdesignicons.css';
import "@fontsource/nunito";
import '@eva/client/styles/index.less';

import VueRouter from 'vue-router';
import Vuetify from 'vuetify/lib';
import Vuelidate from 'vuelidate';
import VueMask from 'v-mask';

Vue.use(VueRouter);
Vue.use(Vuetify, {iconfont: 'mdi'});
Vue.use(Vuelidate);
Vue.use(VueMask);

import 'floating-vue/dist/style.css';
import { VTooltip } from "floating-vue";
Vue.directive('tooltip', VTooltip);

import createPlugins from '@eva/client/plugins';
import locales from '@eva/client/locales';
import WidgetsPlugin from "../../../core/client/core/plugins/widgets/widgets-plugin";

WidgetsPlugin.install();

const widgets = [];

class EvaApp {
  constructor() {
    this.routeManager = new EvaRouteManager(this);
    this.router = new VueRouter({});
    this.settings = reactive({
      header: ''
    });

    let plugins = createPlugins(this);

    Object.assign(this, plugins);

    this.$tools.handleKeys(
      this,
      (plugin) => plugin instanceof EvaPlugin,
      (plugin) => plugin.install()
    );

    Vue.prototype.$eva = this;
  }

  components(...components) {
    components.forEach((component) => {
      Vue.component(component.name, component)
    });
    return this;
  }

  async init() {
    this.vuetify = new Vuetify({
      theme: {
        themes: {
          dark: {
            accent: '#0260CF', 
          },
        },
      },
    });
    this.root = new Vue({
      vuetify: this.vuetify,
      router: this.router,
      render: (h) => h(this.component),
      mounted() {
        document.dispatchEvent(new CustomEvent('appMounted'));
      }
    });

    await this.$tools.handleKeysAsync(
      this,
      (plugin) => plugin instanceof EvaPlugin,
      (plugin) => plugin.init()
    );

    this.$tools.handleKeys(
      locales,
      (messages, locale) => this.$locales.register(locale, messages)
    );


    widgets.forEach(({ component, componentSettings, fields, eventFilters }) => {
      Vue.prototype.$widgets.register(component, componentSettings, fields, eventFilters);
    })

  }

  registerWidget({ component, componentSettings, fields, eventFilters }) {
    widgets.push({
      component,
      componentSettings,
      fields,
      eventFilters
    });
  }

  bootstrap(component) {
    this.component = component;
    this.root.$mount('#app');
  }
}

export default EvaApp;
