import EvaPlugin from "@eva/client/plugins/EvaPlugin";
import EvaOverlay from "./components/EvaOverlay";
import EvaDialog from "./components/EvaDialog";
import EvaToast from "./components/EvaToast";
import EvaConfirmComponent from "./components/EvaConfirmComponent";
import EvaItemSelectComponent from "./components/EvaItemSelectComponent";
import EvaGetTextComponent from "./components/EvaGetTextComponent";

class BoxesEvaPlugin extends EvaPlugin {
  constructor(app) {
    super(app);
    this.boxes = [];
  }

  install() {
    this.app.components(
      EvaOverlay,
      EvaDialog,
      EvaToast
    );
  }

  async notify({ color, header, message }) {
    let box = this.app.$tools.appendComponent({
      component: 'eva-toast',
      propsData: {
        header: this.getHeader(header),
        message,
        color
      }
    });
    box.$on('hide', () => {
      this.app.$tools.dismountComponent(box);
    });
  }

  notifyInfo(message) {
    return this.notify({
      message,
      color: this.app.$styles.getColor('info')
    })
  }

  notifySuccess(message) {
    return this.notify({
      message,
      color: this.app.$styles.getColor('success')
    })
  }

  notifyWarning(message) {
    return this.notify({
      message,
      color: this.app.$styles.getColor('warning')
    })
  }

  notifyError(message) {
    return this.notify({
      message,
      color: this.app.$styles.getColor('error')
    })
  }

  async show({
    type,
    activator,
    anchor,
    header,
    zIndex,
    width,
    parent,
    closeOnCLick,
    component,
    componentProps,
    componentOns,
    commands,
    customCommands,
    commandsComponent,
    commandsComponentProps
  }) {
    return new Promise((resolve) => {
      if (commands) {
        if (typeof commands === 'object') {
          if (commands.cancel == null) {
            commands.cancel = true;
          }
        } else if (Array.isArray(commands)) {
          if (!commands.find((c) => c.name === 'cancel')) {
            commands.push({ name: 'cancel' });
          }
        }
      }
      let box = this.app.$tools.appendComponent({
        component: 'eva-dialog',
        parent,
        propsData: {
          settings: {
            type,
            activator,
            anchor,
            header,
            closeOnCLick,
            component,
            componentProps,
            componentOns,
            commands,
            customCommands,
            commandsComponent,
            commandsComponentProps,
            zIndex,
            width
          }
        }
      });

      this.boxes.push(box);
      this.updateStyles();

      box.$on('begin-hide', () => {
        let index = this.boxes.indexOf(box);
        this.boxes.splice(index, 1);
        this.updateStyles();
      });
      box.$on('hide', (result) => {
        this.app.$tools.dismountComponent(box);
        resolve(result);
      });
      box.show();
    });
  }

  async confirm({ header, message, messageArgs, zIndex }) {
    let res = await this.show({
      type: 'dialog',
      header: this.getHeader(header),
      component: EvaConfirmComponent,
      componentProps: {
        message,
        messageArgs
      },
      zIndex,
      commands: {
        yes: true,
        no: true,
        cancel: false
      }
    });
    return res === 'yes';
  }

  async showForm({ type, activator, anchor, header, model, settings, ok, cancel }) {
    let options = {
      type: type || 'drawer',
      header,
      activator,
      anchor,
      width: settings.width,
      closeOnCLick: true,
      component: 'eva-form',
      componentProps: {
        model,
        settings
      },
      commands: {}
    };
    if (ok) {
      options.commands.ok = ok;
    }

    if (cancel) {
      options.commands.cancel = cancel;
    }

    return await this.show(options);
  }

  async selectItem({ type, activator, parent, header, zIndex, settings }) {
    let result = await this.show({
      type: type || 'dropdown',
      activator,
      parent,
      header,
      zIndex,
      closeOnCLick: true,
      component: EvaItemSelectComponent,
      componentProps: {
        settings
      }
    });
    return result === 'cancel' ? null : result;
  }

  async getText({ type, activator, header, message, ok }) {
    let result = await this.show({
      type: type || 'dropdown',
      activator,
      header,
      closeOnCLick: true,
      component: EvaGetTextComponent,
      componentProps: {
        message,
        ok
      }
    });
    return result === 'cancel' ? null : result;
  }

  updateStyles() {
    let boxes = this.boxes.filter((b) => b.isDrawer);
    if (boxes.length > 0) {
      boxes[boxes.length - 1].updateWidth();
      this.app.root.$nextTick(() => {
        let count = boxes.length - 1;
        let width = boxes[count].$el.getBoundingClientRect().width;
        for (let i = 0; i < count; i++) {
          let cWidth = boxes[i].$el.getBoundingClientRect().width;
          let nWidth = width + this.app.$styles.evaHeader.value * (count - i);
          if (cWidth < nWidth) {
            boxes[i].updateWidth(`${nWidth}px`);
          }
        }
      });
    }
  }

  getHeader(header) {
    if (!header && this.app.$context.platform.platformName) {
      header = this.app.$context.platform.platformName[this.app.$locales.current];
    }
    return header;
  }
}

export default BoxesEvaPlugin;
