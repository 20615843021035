import {
  Fill as FillStyle,
  Icon as IconStyle,
  RegularShape as RegularShapeStyle, Stroke,
  Stroke as StrokeStyle,
  Style as FeatureStyle,
  Text as TextStyle
} from "ol/style";
import Feature from "ol/Feature";
import {LineString, MultiPoint, Point, Polygon} from "ol/geom";
import {ImageStatic as ImageStaticSource, Vector as VectorSource, XYZ as XYZSource} from "ol/source";
import {Image as ImageLayer, Tile as TileLayer, Vector as VectorLayer} from "ol/layer";
import {getCenter} from "ol/extent";
import {Projection} from "ol/proj";
import Map from "ol/Map";
import View from "ol/View";
import {defaults as interactionDefaults} from "ol/interaction/defaults";
import CircleStyle from "ol/style/Circle";
import Collection from "ol/Collection";
import {Modify as ModifyFeature, Translate as TranslateFeature} from "ol/interaction";

import moment from "moment";

const DEFAULT_FONT = 'bold 14px "Open Sans", "Arial Unicode MS", "sans-serif"';
const NO_ICON_SRC = '/assets/images/no_image.png';
const DEFAULT_ICON_SIZE = 22;
const DEFAULT_TEXT_COLOR = '#3E4C5D';
const DEFAULT_POINT_RADIUS = 16;
const DEFAULT_OPACITY = 0.6;
const DEFAULT_BORDER_WIDTH = 2;
const DEFAULT_FEATURE_COLOR = '#000000FE';
const DEFAULT_CLUSTER_COLOR = '#3E4C5DFF';
const DEFAULT_CLUSTER_BORDER_COLOR = '#ACBBCBFF';

class OpenLayersHelper {

  constructor(app) {
    this.app = app;
    this.map = null;
    this.iconStyles = {};
    this.translatedFeatures = null;
    this.modifiedFeatures = null;
    this.isShowNames = false;
  }

  bootstrap(el) {
    this.map.setTarget(el);
  }
  createMap() {
    this.deleteMap();
    this.map = new Map({
      interactions: interactionDefaults({
        doubleClickZoom: false,
        zoomDuration: 0
      }),
      view: new View({
        center: [0, 0],
        zoom: 0
      }),
      controls: []
    });
  }
  createIterations(onFeaturesChanged) {
    function featuresChanged(e) {
      let features = e.features && e.features.getArray();
      if (features && features.length) {
        onFeaturesChanged(features);
      }
    }

    this.translatedFeatures = new Collection([]);
    this.modifiedFeatures = new Collection([]);

    const translate = new TranslateFeature({
      features: this.translatedFeatures,
    });
    const modify = new ModifyFeature({
      features: this.modifiedFeatures
    });

    this.map.addInteraction(translate);
    this.map.addInteraction(modify);

    modify.on('modifyend', featuresChanged);
    translate.on('translateend', featuresChanged);
  }
  deleteMap() {
    if (this.map) {
      this.map.setTarget(null);
      this.map = null;
    }
    this.translatedFeatures = null;
    this.modifiedFeatures = null;
  }

  async createSvgBackground(url, width, height) {
    let data = await this.app.$http.get(url);
    let size = await this.app.$tools.getImageSize(url);
    const style = new FeatureStyle({
      image: new IconStyle({
        opacity: 1,
        anchor: [0,0],
        anchorOrigin: 'bottom-left',
        src: 'data:image/svg+xml;utf8,' + encodeURIComponent(data),
        size : [size.width, size.height]
      })
    });
    const iconFeature = new Feature({
      geometry: new Point([0, 0])
    })
    iconFeature.setStyle((feature, resolution) => {
      style.getImage().setScale(width / size.width / resolution);
      return style;
    });

    const vectorSource = new VectorSource({
      features: [iconFeature]
    });
    const vectorLayer = new VectorLayer({
      source: vectorSource,
      renderBuffer: size.width * 1000
    });
    this.map.addLayer(vectorLayer);
    this.map.setView(new View({
      projection: new Projection({
        code: 'xkcd-image',
        units: 'pixels',
        extent: [0,0,size.width, size.height]
      }),
      center: getCenter([0, 0, width, height]),
      zoom: 0
    }));
  }
  async createImageBackground(url, width, height) {
    const extent = [0,0, width, height];
    const projection = new Projection({
      code: 'xkcd-image',
      units: 'pixels',
      extent: extent,
    });
    let center = getCenter(extent);
    let layer = new ImageLayer({
      source: new ImageStaticSource({
        url: url,
        projection: projection,
        imageExtent: extent,
        center
      })
    });

    this.map.addLayer(layer);
    this.map.setView(new View({
      projection,
      center,
      zoom: 0
    }));
  }
  async createTileBackground(url) {
    this.map.addLayer(new TileLayer({
      source: new XYZSource({
        url: url
      })
    }));
  }

  createTextStyle(offsetY, zIndex, color) {
    const fillStyle = new FillStyle({
      color: color || DEFAULT_TEXT_COLOR
    });
    const textStyle = new TextStyle({
      font: DEFAULT_FONT,
      placement: 'point',
      offsetY,
      overflow: true,
      fill: fillStyle
    });
    return new FeatureStyle({
      text: textStyle,
      zIndex
    });
  }
  getIconStyle(src) {
    if (!src) {
      src = NO_ICON_SRC;
    }
    let iconStyle = this.iconStyles[src];
    if (!iconStyle) {
      iconStyle = new IconStyle({
        src,
        width: DEFAULT_ICON_SIZE,
        height: DEFAULT_ICON_SIZE
      });
      this.iconStyles[src] = iconStyle;
    }
    return iconStyle;
  }
  createPointStyle(isSelected, isHover, isBlink) {
    const zIndex = isSelected ? 103 : isHover ? 102 : 100;
    const backgroundStyle = new FeatureStyle({
      image: new CircleStyle({
        radius: DEFAULT_POINT_RADIUS,
        fill: new FillStyle({ color: DEFAULT_FEATURE_COLOR })
      }),
      zIndex
    });

    const b1 = isBlink ? new FeatureStyle({
      image: new CircleStyle({
        radius: DEFAULT_POINT_RADIUS * 1.4,
        fill: new FillStyle({ color: DEFAULT_FEATURE_COLOR })
      }),
      zIndex
    }) : null;
    const b2 = isBlink ? new FeatureStyle({
      image: new CircleStyle({
        radius: DEFAULT_POINT_RADIUS * 1.7,
        fill: new FillStyle({ color: DEFAULT_FEATURE_COLOR })
      }),
      zIndex
    }) : null;
    const b3 = isBlink ? new FeatureStyle({
      image: new CircleStyle({
        radius: DEFAULT_POINT_RADIUS * 2,
        fill: new FillStyle({ color: DEFAULT_FEATURE_COLOR })
      }),
      zIndex
    }) : null;


    const iconStyle = new FeatureStyle({
      image: this.getIconStyle('/assets/images/no_image.png'),
      zIndex
    });
    const textStyle = this.createTextStyle(DEFAULT_POINT_RADIUS * 1.5);

    let selectionStyle = isSelected ? new FeatureStyle({
      image: new CircleStyle({
        fill: new StrokeStyle({
          color: DEFAULT_FEATURE_COLOR,
          lineDash: [8,8]
        }),
        radius: DEFAULT_POINT_RADIUS * 1.5
      }),
      zIndex
    }) : null;

    const styles = [
      b1,
      b2,
      b3,
      selectionStyle,
      backgroundStyle,
      iconStyle,
      textStyle
    ].filter((s) => !!s);

    function add(s) {
      let index = styles.indexOf(s);
      if (index < 0) {
        styles.splice(0, 0, s);
      }
    }
    function remove(s) {
      let index = styles.indexOf(s);
      if (index >= 0) {
        styles.splice(index, 1);
      }
    }

    return (feature) => {
      if (feature) {
        if (this.isShowNames) {
          textStyle.getText().setText(feature.get('name'));
        } else {
          textStyle.getText().setText('');
        }

        iconStyle.setImage(this.getIconStyle(feature.get('src')));

        let angle = feature.get('angle');
        if (angle != null) {
          angle *= Math.PI / 180;
          iconStyle.getImage().setRotation(angle);
          //backgroundStyle.getImage().setRotation(angle);
        }

        let color = (feature.get('extraColor') || feature.get('color') || DEFAULT_FEATURE_COLOR).trim();

        if (isBlink) {
          let blink = feature.get('blink') || 1;
          b1.getImage().getFill().setColor(this.getOpacityColor(color.trim(), 0.4));
          b2.getImage().getFill().setColor(this.getOpacityColor(color.trim(), 0.4));
          b3.getImage().getFill().setColor(this.getOpacityColor(color.trim(), 0.4));
          b1.getImage().render();
          b2.getImage().render();
          b3.getImage().render();
          switch (blink) {
            case 1:
              remove(b1);
              remove(b2);
              remove(b3);
              break;
            case 2:
            case 6:
              add(b1);
              remove(b2);
              remove(b3);
              break;
            case 3:
            case 5:
              add(b1);
              add(b2);
              remove(b3);
              break;
            case 4:
              add(b1);
              add(b2);
              add(b3);
              break;
          }
        }

        backgroundStyle.getImage().getFill().setColor(isHover ? this.getOpacityColor(color.trim()) : color);
        backgroundStyle.getImage().render();

        if (selectionStyle) {
          selectionStyle.getImage().getFill().setColor(this.getOpacityColor(color.trim(), isHover ? DEFAULT_OPACITY / 2 : DEFAULT_OPACITY));
          selectionStyle.getImage().render();
        }
      }
      return styles;
    }
  }
  createPolygonStyle(isSelected, isHover, isBlink) {
    const zIndex = isSelected ? 103 : isHover ? 102 : 100;
    const textStyle = this.createTextStyle(null, zIndex);

    const radius = DEFAULT_POINT_RADIUS / 3;
    const b1 = isBlink ? new FeatureStyle({
      image: new CircleStyle({
        radius: radius * 1.4,
        fill: new FillStyle({ color: DEFAULT_FEATURE_COLOR })
      }),
      geometry: function (feature) {
        // return the coordinates of the first ring of the polygon
        const coordinates = feature.getGeometry().getCoordinates()[0];
        return new MultiPoint(coordinates);
      },
      zIndex
    }) : null;
    const b2 = isBlink ? new FeatureStyle({
      image: new CircleStyle({
        radius: radius * 1.7,
        fill: new FillStyle({ color: DEFAULT_FEATURE_COLOR })
      }),
      geometry: function (feature) {
        // return the coordinates of the first ring of the polygon
        const coordinates = feature.getGeometry().getCoordinates()[0];
        return new MultiPoint(coordinates);
      },
      zIndex
    }) : null;
    const b3 = isBlink ? new FeatureStyle({
      image: new CircleStyle({
        radius: radius * 2,
        fill: new FillStyle({ color: DEFAULT_FEATURE_COLOR })
      }),
      geometry: function (feature) {
        // return the coordinates of the first ring of the polygon
        const coordinates = feature.getGeometry().getCoordinates()[0];
        return new MultiPoint(coordinates);
      },
      zIndex
    }) : null;

    const fillStyle = new FillStyle({
      color: this.getOpacityColor(DEFAULT_FEATURE_COLOR)
    });
    const strokeStyle = new StrokeStyle({
      color: DEFAULT_FEATURE_COLOR,
      width: DEFAULT_BORDER_WIDTH,
      //lineDash: isSelected ? [8,8] : null
    });
    const sStrokeStyle = new StrokeStyle({
      color: DEFAULT_FEATURE_COLOR,
      width: DEFAULT_BORDER_WIDTH * 6,
      //lineDash: isSelected ? [8,8] : null
    });
    const sFillStyle = new FillStyle({
      color: DEFAULT_FEATURE_COLOR
    });




    const styles = [
      (isSelected || isHover) ? new FeatureStyle({
        fill: sFillStyle,
        stroke: sStrokeStyle,
        zIndex
      }) : null,
      new FeatureStyle({
        fill: fillStyle,
        stroke: strokeStyle,
        zIndex
      }),
      textStyle
    ].filter((f) => !!f);

    function add(s) {
      let index = styles.indexOf(s);
      if (index < 0) {
        styles.splice(0, 0, s);
      }
    }
    function remove(s) {
      let index = styles.indexOf(s);
      if (index >= 0) {
        styles.splice(index, 1);
      }
    }

    return (feature) => {
      if (feature) {
        if (this.isShowNames) {
          textStyle.getText().setText(feature.get('name'));
        } else {
          textStyle.getText().setText('');
        }

        let color = (feature.get('extraColor') || feature.get('color') || DEFAULT_FEATURE_COLOR).trim();

        fillStyle.setColor(this.getOpacityColor(color, 0.4));
        strokeStyle.setColor(this.getOpacityColor(color, 0.4));

        if (isHover) {
          sStrokeStyle.setColor(this.getOpacityColor(color, 0.4));
          sFillStyle.setColor(this.getOpacityColor(color, 0.4));
        } else if (isSelected) {
          sStrokeStyle.setColor(this.getOpacityColor(color, 0.8));
          sFillStyle.setColor(this.getOpacityColor(color, 0.8));
        } else if (isBlink) {
          let blink = feature.get('blink') || 1;
          strokeStyle.setColor(this.getOpacityColor(color, 0.8));
          switch (blink) {
            case 1:
              fillStyle.setColor(this.getOpacityColor(color, 0.2));
              break;
            case 2:
            case 6:
              fillStyle.setColor(this.getOpacityColor(color, 0.4));
              break;
            case 3:
            case 5:
              fillStyle.setColor(this.getOpacityColor(color, 0.6));
              break;
            case 4:
              fillStyle.setColor(this.getOpacityColor(color, 0.8));
              break;
          }
        }

        if (isBlink === 1) {
          let blink = feature.get('blink') || 1;
          b1.getImage().getFill().setColor(this.getOpacityColor(color.trim(), 0.4));
          b2.getImage().getFill().setColor(this.getOpacityColor(color.trim(), 0.4));
          b3.getImage().getFill().setColor(this.getOpacityColor(color.trim(), 0.4));
          b1.getImage().render();
          b2.getImage().render();
          b3.getImage().render();
          switch (blink) {
            case 1:
              remove(b1);
              remove(b2);
              remove(b3);
              break;
            case 2:
            case 6:
              add(b1);
              remove(b2);
              remove(b3);
              break;
            case 3:
            case 5:
              add(b1);
              add(b2);
              remove(b3);
              break;
            case 4:
              add(b1);
              add(b2);
              add(b3);
              break;
          }
        }
      }
      return styles;
    };
  }
  createLineStyle(isSelected, isHover, isBlink) {
    return this.createPolygonStyle(isSelected, isHover, isBlink);
  }
  createClusterStyle(isSelected, isHover, isBlink) {
    const zIndex = 103;
    const backgroundStyle = new FeatureStyle({
      image: new CircleStyle({
        radius: DEFAULT_POINT_RADIUS,
        fill: new FillStyle({ color: DEFAULT_CLUSTER_COLOR }),
        stroke: new Stroke({
          color: DEFAULT_CLUSTER_BORDER_COLOR,
          width: DEFAULT_BORDER_WIDTH
        }),
      }),
      zIndex
    });

    const b1 = isBlink ? new FeatureStyle({
      image: new CircleStyle({
        radius: DEFAULT_POINT_RADIUS * 1.4,
        fill: new FillStyle({ color: DEFAULT_CLUSTER_COLOR })
      }),
      zIndex
    }) : null;
    const b2 = isBlink ? new FeatureStyle({
      image: new CircleStyle({
        radius: DEFAULT_POINT_RADIUS * 1.7,
        fill: new FillStyle({ color: DEFAULT_CLUSTER_COLOR })
      }),
      zIndex
    }) : null;
    const b3 = isBlink ? new FeatureStyle({
      image: new CircleStyle({
        radius: DEFAULT_POINT_RADIUS * 2,
        fill: new FillStyle({ color: DEFAULT_CLUSTER_COLOR })
      }),
      zIndex
    }) : null;

    const textStyle = this.createTextStyle(null, zIndex, DEFAULT_CLUSTER_BORDER_COLOR);

    let selectionStyle = isSelected ? new FeatureStyle({
      image: new CircleStyle({
        fill: new StrokeStyle({
          color: DEFAULT_CLUSTER_COLOR,
          lineDash: [8,8]
        }),
        radius: DEFAULT_POINT_RADIUS * 1.5
      }),
      zIndex
    }) : null;

    const styles = [
      b1,
      b2,
      b3,
      selectionStyle,
      backgroundStyle,
      textStyle
    ].filter((s) => !!s);

    function add(s) {
      let index = styles.indexOf(s);
      if (index < 0) {
        styles.splice(0, 0, s);
      }
    }
    function remove(s) {
      let index = styles.indexOf(s);
      if (index >= 0) {
        styles.splice(index, 1);
      }
    }

    return (feature) => {

      if (feature) {
        const features = feature.get('features');
        if (!features || features.length < 2) {
          return [];
        }
        textStyle.getText().setText(feature.get('features').length.toString());
        let color = (feature.get('color') || DEFAULT_CLUSTER_COLOR).trim();
        if (isBlink) {
          let blink = feature.get('blink') || 1;
          b1.getImage().getFill().setColor(this.getOpacityColor(color.trim(), 0.4));
          b2.getImage().getFill().setColor(this.getOpacityColor(color.trim(), 0.4));
          b3.getImage().getFill().setColor(this.getOpacityColor(color.trim(), 0.4));
          b1.getImage().render();
          b2.getImage().render();
          b3.getImage().render();
          switch (blink) {
            case 1:
              remove(b1);
              remove(b2);
              remove(b3);
              break;
            case 2:
            case 6:
              add(b1);
              remove(b2);
              remove(b3);
              break;
            case 3:
            case 5:
              add(b1);
              add(b2);
              remove(b3);
              break;
            case 4:
              add(b1);
              add(b2);
              add(b3);
              break;
          }
        }

        if (selectionStyle) {
          selectionStyle.getImage().getFill().setColor(this.getOpacityColor(color.trim(), isHover ? DEFAULT_OPACITY : 1));
          selectionStyle.getImage().render();
        }
      }
      return styles;
    }
  }

  createPointFeature(monitoringObject, mapObject, drawStyle) {
    return new Feature({
      geometry: new Point(mapObject.coords.point),
      name: monitoringObject.name,
      angle: mapObject.coords.angle,
      data_id: mapObject.id,
      mo_id: monitoringObject.id,
      src: drawStyle.ref_fileobject.download_url,
      drawStyleId: drawStyle.id,
      color: monitoringObject.status && monitoringObject.status.color
    });
  }
  createLineFeature(monitoringObject, mapObject, drawStyle) {
    return new Feature({
      geometry: new LineString(mapObject.coords.points),
      name: monitoringObject.name,
      angle: mapObject.coords.angle,
      data_id: mapObject.id,
      mo_id: monitoringObject.id,
      drawStyleId: drawStyle.id,
      color: monitoringObject.status && monitoringObject.status.color
    });
  }
  createPolygonFeature(monitoringObject, mapObject, drawStyle) {
    return new Feature({
      geometry: new Polygon([mapObject.coords.points]),
      name: monitoringObject.name,
      angle: mapObject.coords.angle,
      data_id: mapObject.id,
      mo_id: monitoringObject.id,
      drawStyleId: drawStyle.id,
      color: monitoringObject.status && monitoringObject.status.color
    });
  }

  getView() {
    const view = this.map.getView();
    const center = view.getCenter();
    const zoom = view.getZoom();
    return {
      x: center[0],
      y: center[1],
      zoom
    }
  }
  setView(x, y, zoom) {
    this.map.getView().animate({
      center: [
        x,
        y
      ],
      zoom: zoom,
      duration: 0
    });
  }
  fit(x, y, width, height) {
    this.map.getView().fit([x, y, width, height], {
      constrainResolution: false,
      padding: [10, 10, 10, 10],
      duration: 0
    });
  }
  setZoom(rate) {
    this.map.getView().animate({
      zoom: this.map.getView().getZoom() + rate,
      duration: 0
    });
  }


  getOpacityColor(color, opacity = DEFAULT_OPACITY) {
    if (color[0] !== '#') {
      return color;
    }
    if (color.length === 9) {
      opacity *= parseInt(color.substring(7, 9), 16) / 255;
      color = color.substring(0, 7);
    }
    opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
    return `${color}${opacity.toString(16)}`;
  }

  redraw() {
    if (this.map) {
      this.map.getLayers().forEach((layer) => layer.getSource().changed());
    }
  }

  async getScreenshot({ isBlob = false, isBase64 = false }) {
    const map = this.map;
    const mapCanvas = document.createElement('canvas');
    map.render();
    return new Promise((resolve, reject) => map.once('rendercomplete', async () => {
      try {
        const size = map.getSize();
        mapCanvas.width = size[0];
        mapCanvas.height = size[1];
        const mapContext = mapCanvas.getContext('2d');
        for (let canvas of map.getViewport().querySelectorAll('.ol-layer canvas, canvas.ol-layer')) {
          if (canvas.width > 0) {
            const opacity =
              canvas.parentNode.style.opacity || canvas.style.opacity;
            mapContext.globalAlpha = opacity === '' ? 1 : Number(opacity);
            let matrix;
            const transform = canvas.style.transform;
            if (transform) {
              // Get the transform parameters from the style's transform matrix
              matrix = transform
                .match(/^matrix\(([^\(]*)\)$/)[1]
                .split(',')
                .map(Number);
            } else {
              matrix = [
                parseFloat(canvas.style.width) / canvas.width,
                0,
                0,
                parseFloat(canvas.style.height) / canvas.height,
                0,
                0,
              ];
            }
            // Apply the transform to the export map context
            CanvasRenderingContext2D.prototype.setTransform.apply(
              mapContext,
              matrix
            );
            const backgroundColor = canvas.parentNode.style.backgroundColor;
            if (backgroundColor) {
              mapContext.fillStyle = backgroundColor;
              mapContext.fillRect(0, 0, canvas.width, canvas.height);
            }
            mapContext.drawImage(canvas, 0, 0);
          }
        }

        mapContext.globalAlpha = 1;
        mapContext.setTransform(1, 0, 0, 1, 0, 0);
        const dataURL = mapCanvas.toDataURL();
        
        if (!isBlob && !isBase64) {
          const link = document.createElement('a');
          link.href = dataURL;
          const time = moment(Date.now(), 'x').format('DD.MM.YYYY HH.mm');
          link.download = `${time}.png`;
          link.target ="_blank";
          document.body.appendChild(link);
          link.click();
          link.remove();
        } else if (isBase64) {
          resolve(dataURL.replace(/^data:image\/?[A-z]*;base64,/, ''));
        } else {
          mapCanvas.toBlob((blob) => {
            resolve(blob);
          })
        }
      } catch(err) {
        reject(err);
      }
    }));
  }
}

export default OpenLayersHelper;
