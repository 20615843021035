import { watch } from "vue";

export default {
  props: {
    settings: {
      type: Object
    },
    formSettings: {
      type: Object
    },
    model: {
      type: Object
    },
    validators: {
      type: Array
    },
    designMode: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      $parentModel: null,
      regexDynamicParam: /\{([^}]+)\}/g,
      fieldErrors: ''
    }
  },

  computed: {
    readOnly() {
      if (this.formSettings.readOnly === true) {
        return true;
      }
      if (typeof this.settings.readOnly === 'function') {
        return this.settings.readOnly(this.model);
      }
      return this.settings.readOnly;
    },
    disabled() {
      if (this.formSettings.disabled === true) {
        return true;
      }
      if (typeof this.settings.disabled === 'function') {
        return this.settings.readOnly(this.model);
      }
      return this.settings.disabled;
    },
    preview() {
      if (typeof this.formSettings.preview === 'function') {
        return this.formSettings.preview(this.model);
      }

      if (typeof this.settings.preview === 'function') {
        return this.settings.preview(this.model);
      }

      return !!this.settings.preview || !!this.formSettings.preview;
    },
    depth() {
      if (Number.isFinite(this.formSettings.depth)) {
        return this.formSettings.depth;
      }

      if (Number.isFinite(this.settings.depth)) {
        return this.settings.depth;
      }
      
      return 1;
    },
    maxLength() {
      return this.formSettings.maxLength || this.settings.maxLength;
    },
    counter() {
      return !!this.formSettings.maxLength || !!this.settings.maxLength;
    },
    modelValue: {
      get() {
        return this.$eva.$metadatas.getModelValue(this.settings, this.model);
      },
      set(value) {
        return this.$eva.$metadatas.setModelValue(this.settings, this.model, value);
      }
    },
    fieldLabel() {
      let result = this.$eva.$tal(
        `$t.${this.formSettings.prefix}.${this.settings.name}.header`,
        `$t.${this.formSettings.prefix}.columns.${this.settings.name}.header`,
        this.settings.label
      );
      return result === 'false' ? '' : result;
    },
    fieldTitle() {
      return this.$eva.$ta(
        `$t.${this.formSettings.prefix}.${this.settings.name}.title`,
        `$t.${this.formSettings.prefix}.columns.${this.settings.name}.title`
      );
    },
    fieldPlaceholder() {
      return this.$eva.$ta(
        `$t.${this.formSettings.prefix}.${this.settings.name}.placeholder`,
        `$t.${this.formSettings.prefix}.columns.${this.settings.name}.placeholder`,
        `$t.${this.formSettings.prefix}.filter.columns.${this.settings.name}.placeholder`
      );
    },
    hasErrors() {
      return !!this.fieldErrors;
    },
    paddingClasses() {
      let result = [];
      if (this.settings.padding) {
        result.push('eva-padding');
      }
      if (this.settings.paddingTop) {
        result.push('eva-padding-top');
      }
      if (this.settings.paddingBottom) {
        result.push('eva-padding-bottom');
      }
      if (this.settings.paddingLeft) {
        result.push('eva-padding-left');
      }
      if (this.settings.paddingRight) {
        result.push('eva-padding-right');
      }
      return result;
    },
    paddingStyles() {
      let result = {};
      if (this.settings.paddingStyle) {
        result.padding = this.settings.paddingStyle;
      }
      if (this.settings.marginStyle) {
        result.margin = this.settings.marginStyle;
      }
      return result;
    },
    isHide() {
      if (!this.settings || !this.formSettings) {
        return false;
      }
      if (!this.settings.hideEmpty && !this.formSettings.hideEmpty) {
        return false;
      }
      if (this.preview || !this.readOnly || this.designMode) {
        return false;
      }
      let value = this.modelValue;
      if (value == null || value === '') {
        return true;
      }
      if (Array.isArray(value) && !value.length) {
        return true;
      }
      if (typeof value === 'object' && !Object.keys(value).length) {
        return true;
      }

      switch (this.settings.type) {
        case 'datetime':
        case 'date':
        case 'time':
          return value === 0;
        default:
          return false;
      }
    },
    customError: {
      get() {
        return this.settings.error;
      },
      set(value) {
        this.$set(this.settings, 'error', value);
      }
    }
  },

  watch: {
    async 'settings.initialized'() {
      this.fieldErrors = await this.getErrors('initialized');
    },
    async 'settings.error'() {
      this.fieldErrors = await this.getErrors('error');
    },
    async 'validators'() {
      this.fieldErrors = await this.getErrors('validators');
    },
    'modelValue'() {
      if (this.count == null) {
        this.count = 1;
      } else {
        this.count = this.count + 1;
      }
      let count = this.count;
      this.getErrors('modelValue').then((errors) => {
        if (count === this.count) {
          this.fieldErrors = errors;
          this.count = null;
        }
      });
    },
    async 'model'() {
      this.fieldErrors = await this.getErrors('model');
    },
    hasErrors(value) {
      let errors = this.formSettings.errors;
      if (value) {
        if (errors) {
          if (errors.indexOf(this.settings.name) < 0) {
            errors.push(this.settings.name);
          }
        } else {
          this.$set(this.formSettings, 'errors', [this.settings.name]);
        }
      } else {
        if (errors) {
          let index = errors.indexOf(this.settings.name);
          if (index >= 0) {
            errors.splice(index, 1);
          }
          if (!errors.length) {
            this.$set(this.formSettings, 'errors', null);
          }
        }
      }
    }
  },

  methods: {
    getDynamicValue(name) {
      if (typeof name === 'string') {
        if (name.startsWith('$current')) {
          return this.$eva.$tools.getNestedValue(this.model, name.substring(9));
        }
        if (name.startsWith('$context')) {
          return this.$eva.$tools.getNestedValue(this.$context, name.substring(9));
        }
        if (name.startsWith('$parent')) {
          return this.$eva.$tools.getNestedValue(this.formSettings.$parentModel, name.substring(8));
        }
      }
      return name;
    },
    async getErrors(name) {
      let errors = [];
      if (!this.designMode && (name === 'error' || this.settings.initialized)) {
        if (this.validators) {
          for (let i = 0; i < this.validators.length; i++) {
            let item = this.validators[i];
            let error = await item.validator.validate(this.modelValue, item, this.model, this.settings);
            if (error) {
              errors.push(error);
            }
          }
        }
        if (this.settings.error) {
          errors.push(this.settings.error);
        }
      }
      return errors.join(' ');
    }
  },

  mounted() {
    if (this.settings.validatorsTrigger) {
      this.settings.validatorsTrigger.split(',').map(() => {
        watch(() => this.$eva.$tools.getNestedValue(this.model, this.settings.validatorsTrigger), async () => {
          this.fieldErrors = await this.getErrors('validatorsTrigger');
        });
      });
    }
  }
}
