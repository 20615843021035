<template>
  <v-app class="eva-page--obsolete" :class="classes">
    <div class="eva-page eva-background-1">
      <slot/>
    </div>
  </v-app>
</template>

<script>
export default {
  name: 'eva-page',

  computed: {
    classes() {
      return this.$eva.$themes.cssClass;
    }
  }
}
</script>

<style lang="less">
.eva-page--obsolete {
  width: 100%;
  height: 100%;
}
.eva-page {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
</style>
