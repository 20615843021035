import { computed, watch, ref } from "vue";
import Vue from "vue";
import { v4 } from "uuid";
import evaApp from '@eva/client/app';

const DEFAULT_TYPE = 'text';
const DEFAULT_NAME = 'attr';
const DEFAULT_DESCRIPTION = 'Новый параметр';

function createRawWrapper(object, name, defaultValue = null, validate = null) {
  Vue.set(object, name, object[name] == null ? defaultValue : object[name]);
  return computed({
    get() {
      return object[name];
    },
    set(value) {
      if (!validate || validate(value)) {
        object[name] = value;
      }
    }
  })
}

function validateModel(model) {
  if (model == null) {
    model = {};
  }

  const prefix = evaApp.$tools.getNestedValue(model, 'params.settings.prefix') || v4();
  const defaultModel = {
    role_search: {},
    fields: {},
    params: {
      settings: {
        prefix: prefix,
        columns: [],
        commands: true,
        width: '600px',
        layouts: {
          main: {
            type: 'grid',
            fields: { }
          }
        }
      },
      locales: {
        ru: {
          [prefix]: {
            columns: { }
          }
        }
      }
    }
  }

  model = evaApp.$tools.setDeepDefaults(model, defaultModel);
  model.params.settings.columns = evaApp.$tools.mapObjectOrArray(model.params.settings.columns, 'name', (res) => res);

  const locales = model.params.locales.ru[prefix];
  const fields = model.fields;
  const columns = model.params.settings.columns;
  const layouts = model.params.settings.layouts.main.fields;

  for (let i = 0; i < columns.length; i++) {
    if (!fields[columns[i].name]) {
      fields[columns[i].name] = {
        description: locales.columns[columns[i].name] && locales.columns[columns[i].name].header || columns[i].name
      };
    }
  }

  const fieldNames = Object.keys(fields);
  for (let i = 0; i < fieldNames.length; i++) {
    const name = fieldNames[i];
    if (!columns.find((c) => c.name === name)) {
      columns.push({
        name
      });
    }
  }

  if (model.params.settings.layouts.main.w == null) {
    model.params.settings.layouts.main.w = 1;
  }
  if (model.params.settings.layouts.main.h == null) {
    model.params.settings.layouts.main.h = columns.length || 1;
  }

  for (let i = 0; i < columns.length; i++) {
    const column = columns[i];
    if (!column.type) {
      column.type = DEFAULT_TYPE;
    }
    if (!locales.columns[column.name]) {
      locales.columns[column.name] = {};
    }
    if (!locales.columns[column.name].header) {
      locales.columns[column.name].header = fields[column.name].description || '';
    }
    if (!locales.columns[column.name].placeholder) {
      locales.columns[column.name].placeholder = '';
    }
    if (!locales.columns[column.name].title) {
      locales.columns[column.name].title = '';
    }
    if (!column.validators || Array.isArray(column.validators)) {
      column.validators = {};
    }
    if (!layouts[column.name]) {
      layouts[column.name] = {
        w: 1,
        h: 1,
        x: 0,
        y: i
      }
    }
  }

  Object.keys(model).forEach((key) => {
    Vue.set(model, key, evaApp.$tools.clone(model[key]));
  });

  return model;
}

function createMetadataWrapper(model, eva) {

  model = validateModel(model);

  const fieldsRaw = model.fields;
  const columnsRaw = model.params.settings.columns;
  const localesRaw = model.params.locales.ru[model.params.settings.prefix];
  const layouts = model.params.settings.layouts.main.fields;

  function createFieldWrapper(columnRaw) {
    const fieldRaw = fieldsRaw[columnRaw.name];

    const id = computed(() => columnRaw.name);
    const name = ref(columnRaw.name);
    const nameError = ref('');
    const customError = ref(false);
    const description = computed({
      get() {
        return localesRaw.columns[columnRaw.name] && localesRaw.columns[columnRaw.name].header;
      },
      set(value) {
        Vue.set(localesRaw.columns[columnRaw.name], 'header', value);
      }
    });
    const placeholder = computed({
      get() {
        return localesRaw.columns[columnRaw.name] && localesRaw.columns[columnRaw.name].placeholder;
      },
      set(value) {
        Vue.set(localesRaw.columns[columnRaw.name], 'placeholder', value);
      }
    });
    const title = computed({
      get() {
        return localesRaw.columns[columnRaw.name] && localesRaw.columns[columnRaw.name].title;
      },
      set(value) {
        Vue.set(localesRaw.columns[columnRaw.name], 'title', value);
      }
    });
    const icon = computed(() => {
      if (fieldRaw.show_flag === false) {
        return 'mdi-eye-off';
      }
      if (fieldRaw.system_field_flag === true) {
        return 'mdi-table';
      }
      return 'mdi-plus-circle';
    });
    const isSystem = ref(fieldRaw.system_field_flag === true);
    const attrs_type = createRawWrapper(fieldRaw, 'attrs_type', 'str');
    const type = createRawWrapper(columnRaw, 'type', 'text');
    const type_service = createRawWrapper(fieldRaw, 'type_service', null);
    const service_version = createRawWrapper(fieldRaw, 'service_version', 'v3');
    const type_object = createRawWrapper(fieldRaw, 'type_object', null);

    function getValidator(name) {
      return columnRaw.validators[name];
    }
    function setValidator(name, value) {
      if (value) {
        Vue.set(columnRaw.validators, name, value);
      } else {
        Vue.delete(columnRaw.validators, name);
      }
    }

    const validatorRequire = computed({
      get() {
        return !!getValidator('require');
      },
      set(value) {
        setValidator('require', value ? {} : null)
      }
    });
    const validatorMin = computed({
      get() {
        let validator = getValidator('range');
        return validator && validator.min;
      },
      set(value) {
        let validator = getValidator('range');
        if (value != null) {
          if (!validator) {
            validator = {};
          }
          Vue.set(validator, 'min', value);
          setValidator('range', validator);
        } else if (validator) {
          Vue.delete(validator, 'min');
          if (validator.max == null) {
            validator = null;
          }
          setValidator('range', validator);
        }
      }
    });
    const validatorMax = computed({
      get() {
        let validator = getValidator('range');
        return validator && validator.max;
      },
      set(value) {
        let validator = getValidator('range');
        if (value != null) {
          if (!validator) {
            validator = {};
          }
          Vue.set(validator, 'max', value);
          setValidator('range', validator);
        } else if (validator) {
          Vue.delete(validator, 'max');
          if (validator.min == null) {
            validator = null;
          }
          setValidator('range', validator);
        }
      }
    });

    watch([name, attrs_type], ([nameValue, typeValue]) => {

      nameValue = (nameValue || '').trim();
      if (!nameValue) {
        nameError.value = 'Название не может быть пустым';
        return;
      }
      if (columnsRaw.find((c) => c.name === nameValue && c !== columnRaw)) {
        nameError.value = 'Название совпадает с другим полем';
        return;
      }
      switch (typeValue) {
        case 'ref':
        case 'ref_list':
          if (!nameValue.startsWith('ref_')) {
            nameError.value = `У поля с типами 'Ссылка на объект' или 'Список ссылок на объект' название должно начинаться с префикса 'ref_'`;
            return;
          }
          break;
        default:
          if (nameValue.startsWith('ref_')) {
            nameError.value = `Название поля, начинающееся с префикса 'ref_' должно быть только у типов 'Ссылка на объект' или 'Список ссылок на объект'`;
            return;
          }
          break;
      }

      nameError.value = '';

      const oldValue = columnRaw.name;
      if (oldValue === nameValue) {
        return;
      }

      columnRaw.name = nameValue;

      Vue.set(localesRaw.columns, nameValue, localesRaw.columns[oldValue]);
      Vue.delete(localesRaw.columns, oldValue);

      Vue.set(fieldsRaw, nameValue, fieldRaw);
      Vue.delete(fieldsRaw, oldValue);

      Vue.set(layouts, nameValue, layouts[oldValue]);
      Vue.delete(layouts, oldValue);
    });
    watch(description, (value) => {
       fieldRaw.description = value;
    });
    watch(type, () => {
      validatorRequire.value = false;
      validatorMin.value = null;
      validatorMax.value = null;
      Vue.set(columnRaw, 'min', null);
      Vue.set(columnRaw, 'max', null);
    });
    watch(attrs_type, (value) => {
      if (value === 'ref' || value === 'ref_list') {
        if (name.value.indexOf('ref_') !== 0) {
          name.value = `ref_${name.value}`;
        }
      } else {
        if (name.value.indexOf('ref_') === 0) {
          name.value = name.value.substring(4, name.value.length);
        }
      }
    });
    watch([attrs_type, type], () => {
      if (type.value === 'select' && attrs_type.value === 'str') {
        columnRaw.return = 'id';
      } else  {
        delete columnRaw.return;
      }
      if (type.value === 'catalog-ref') {
        if (attrs_type.value === 'ref') {
          columnRaw.multiple = false;
        } else if (attrs_type.value === 'ref_list') {
          columnRaw.multiple = true;
        }
      }
      if (attrs_type.value !== 'ref' && attrs_type.value !== 'ref_list') {
        type_service.value = null;
        service_version.value = null;
        type_object.value = null;
      }
    });

    const x = createRawWrapper(layouts[name.value], 'x');
    const y = createRawWrapper(layouts[name.value], 'y');
    const w = createRawWrapper(layouts[name.value], 'w');
    const h = createRawWrapper(layouts[name.value], 'h');

    function isIntersects(field) {
      return !(
        x.value >= (field.x + field.w) ||
        (x.value + w.value) <= field.x ||
        y.value >= (field.y + field.h) ||
        (y.value + h.value) <= field.y
      );
    }

    watch([x,y,w,h], ([x1,y1,w1,h1], [x2,y2,w2,h2]) => {
      function revert(reason) {
        x.value = x2;
        y.value = y2;
        w.value = w2;
        h.value = h2;
        eva.$boxes.notifyError(reason);
      }
      if (x1 < 0 || y1 < 0 || (x1 + w1) > lw.value || (y1 + h1) > lh.value) {
        return revert('Невозможно изменить параметры местоположения поля, так как оно уходит за границы сетки.')
      }
      let intersection = columns.find((field) => field.id !== id.value && isIntersects(field));
      if (intersection) {
        return revert('Невозможно изменить параметры местоположения поля, так как оно пересекается с другим полем.')
      }
    });

    const hasError = computed(() => !!nameError.value || customError.value);

    return {
      model,
      id,
      name,
      description,
      placeholder,
      title,
      icon,
      isSystem,
      nameError: computed(() => nameError.value),
      attrs_type,
      show_flag: createRawWrapper(fieldRaw, 'show_flag', true),
      type_service,
      service_version,
      type_object,
      type,
      showInRepeater: createRawWrapper(columnRaw, 'showInRepeater', true),
      readOnly: createRawWrapper(columnRaw, 'readOnly', false),
      validators: createRawWrapper(columnRaw, 'validators'),
      x,
      y,
      w,
      h,
      hasError,
      customError,
      columnRaw,
      fieldRaw,
      validatorRequire,
      validatorMin,
      validatorMax
    };
  }

  const columns = columnsRaw.map(createFieldWrapper);

  function getNewName(array, field, name) {
    let i = 0;
    while (true) {
      let newName = `${name}${++i}`;
      if (!array.find((a) => a[field] === newName)) {
        return newName;
      }
    }
  }
  function addColumn() {
    let name = getNewName(columns, 'name', DEFAULT_NAME);
    let description = getNewName(columns, 'description', DEFAULT_DESCRIPTION);

    if (lh.value <= columns.length) {
      lh.value = columns.length + 1;
    }

    Vue.set(fieldsRaw, name, {
      attrs_type: 'str',
    });
    Vue.set(localesRaw.columns, name, {
      header: description,
      placeholder: ''
    });
    Vue.set(layouts, name, {
      x: 0,
      y: columns.length,
      w: 1,
      h: 1
    });
    let column = {
      name: name,
      type: 'text',
      showInRepeater: true,
      validators: {}
    };
    columnsRaw.push(column);
    columns.push(createFieldWrapper(column));

    return columns[columns.length - 1];
  }
  function removeColumn(column) {
    delete fieldsRaw[column.name];
    delete localesRaw.columns[column.name];
    delete layouts[column.name];
    let index = columns.indexOf(column);
    if (index >= 0) {
      columns.splice(index, 1);
    }
    let raw = columnsRaw.find((c) => c.name === column.name);
    if (raw) {
      let index = columnsRaw.indexOf(raw);
      if (index >= 0) {
        columnsRaw.splice(index, 1);
      }
    }
    if (index >= columns.length) {
      index = columns.length - 1;
    }
    return index >= 0 ? columns[index] : null;
  }
  function moveUp(column) {
    let index = columns.indexOf(column);
    if (index < 1) {
      return;
    }
    let newIndex = index - 1;
    columns.splice(index, 1);
    columns.splice(newIndex, 0, column);
    let raw = columnsRaw[index];
    columnsRaw.splice(index, 1);
    columnsRaw.splice(newIndex, 0, raw);
  }
  function moveDown(column) {
    let index = columns.indexOf(column);
    if (index < 0 || index >= columns.length - 1) {
      return;
    }
    let newIndex = index + 1;
    columns.splice(index, 1);
    columns.splice(newIndex, 0, column);
    let raw = columnsRaw[index];
    columnsRaw.splice(index, 1);
    columnsRaw.splice(newIndex, 0, raw);
  }

  const service = createRawWrapper(model, 'type_service');
  const object = createRawWrapper(model, 'type_object');
  const name = createRawWrapper(model, 'meta_name');
  const variant = ref('');
  if (model.system_object_flag) {
    variant.value = 'system';
  } else if (object.value === name.value) {
    variant.value = 'new';
  } else {
    variant.value = 'inherit';
  }
  let description = createRawWrapper(model, 'name');

  Vue.set(localesRaw, 'caption', model.name);
  watch(description, (value) => {
    Vue.set(localesRaw, 'caption', value);
  });

  let widthValue = ref(null);
  let widthUnit = ref(null);
  watch([widthValue, widthUnit], () => {
    Vue.set(
      model.params.settings,
      'width',
      (widthValue.value && widthUnit.value)
        ? `${widthValue.value}${widthUnit.value}`
        : null
    );
  });
  let width = model.params.settings.width;
  if (width) {
    let parts = width.split(/(?<=\D)(?=\d)|(?<=\d)(?=\D)/);
    if (parts && parts.length === 2) {
      widthValue.value = parseInt(parts[0]);
      widthUnit.value = parts[1];
    }
  }

  const lh = createRawWrapper(model.params.settings.layouts.main, 'h');
  const lw = createRawWrapper(model.params.settings.layouts.main, 'w');
  watch([lw, lh], ([lw1, lh1], [lw2, lh2]) => {
    const column = columns.find((c) => (c.x + c.w) > lw1 || (c.y + c.h) > lh1);
    if (
      column && (
        (column.x + column.w) > lw1 ||
        (column.y + column.h) > lh1
      )
    ) {
      lw.value = lw2;
      lh.value = lh2;
      eva.$boxes.notifyError('Невозможно изменить размеры сетки, так как появляются поля, выходящие за границы.');
    }
  });

  return ref({
    service,
    object,
    name,
    variant,
    description,
    widthValue,
    widthUnit,
    width: createRawWrapper(model.params.settings, 'width'),
    h: lh,
    w: lw,
    columns,
    addColumn,
    removeColumn,
    moveUp,
    moveDown
  });
}

export default createMetadataWrapper;
