import {computed, reactive, ref, watch} from "vue";
import OpenLayersHelper from "./OpenLayersHelper";
import {v4} from "uuid";
import {Vector as VectorLayer} from "ol/layer";
import {Vector as VectorSource, Cluster as ClusterSource} from "ol/source";
import {Point} from "ol/geom";
import {extend} from 'ol/extent';

const MAP_SHOW_NAMES = 'MAP_SHOW_NAMES';
const ZOOM_RATE = 0.5;

function createEvaMapWrapper(app, readOnly, blinkOnAlarm, hideGroups, parent) {

  const olHelper = new OpenLayersHelper(app);

  const hasAlarm = ref(false);
  let blinkObjects = [];
  let blinkInterval = null;
  function addBlink(obj) {
    if (blinkObjects.indexOf(obj) < 0) {
      blinkObjects.push(obj);
    }
    if (!blinkInterval) {
      blinkInterval = setInterval(handleBlink, 200);
      hasAlarm.value = true;
    }
  }
  function removeBlink(obj) {
    let index = blinkObjects.indexOf(obj);
    obj.set('blink', undefined);
    if (index >= 0) {
      blinkObjects.splice(index, 1);
    }
    if (!blinkObjects.length) {
      hasAlarm.value = false;
      if (blinkInterval) {
        clearInterval(blinkInterval);
        blinkInterval = null;
      }
      for (let i = 0; i < clusters.length; i++) {
        const features = clusters[i].get('features');
        if (!features || features.length < 2) {
          clusters[i].set('blink', undefined);
          clusters[i].set('color', undefined);
          clusters[i].setStyle(clusterStyle);
        }
      }
    }
  }
  function handleBlink() {
    for (let i = 0; i < blinkObjects.length; i++) {
      let feature = blinkObjects[i];
      let blink = feature.get('blink') || 0;
      blink++;
      if (blink > 6) {
        blink = 1;
      }
      feature.set('blink', blink);
    }
    const clusters = clusterSource.getFeatures();
    for (let i = 0; i < clusters.length; i++) {
      const features = clusters[i].get('features');
      if (!features || features.length < 2) {
        clusters[i].set('blink', undefined);
        clusters[i].set('color', undefined);
        clusters[i].setStyle(clusterStyle);
        continue;
      }
      const blinked = features.find((f) => !!f.get('blink'));
      if (blinked) {
        clusters[i].set('blink', blinked.get('blink'));
        clusters[i].set('color', blinked.get('color'));
        clusters[i].setStyle(clusterBlinkStyle);
      }
    }
    olHelper.redraw();
  }

  const id = ref(v4());
  const selectedMapObjects = ref([]);
  const selectedMonitoringObject = ref(null);
  let selectedMapObjectsIniting = false;

  const tooltip = ref(null);
  const group = ref(null);

  const clusterVectorSource = new VectorSource();
  const clusterSource = new ClusterSource({
    distance: 32,
    minDistance: 32,
    source: clusterVectorSource,
  });

  let c = 0;
  clusterSource.on('addfeature', () => {
    const features = clusterSource.getFeatures();
    for (let i = 0; i < features.length; i++) {
      if (features[i].get('features').length === 1) {
        let layer = layers.value.find((l) => l.drawStyleId === features[i].get('features')[0].get('drawStyleId'));
        if (!layer.source.hasFeature(features[i].get('features')[0])) {
          layer.source.addFeature(features[i].get('features')[0]);
        }
      } else {
        for (let j = 0; j < features[i].get('features').length; j++) {
          let layer = layers.value.find((l) => l.drawStyleId === features[i].get('features')[j].get('drawStyleId'));
          layer.source.removeFeature(features[i].get('features')[j]);
        }
      }
    }
  })

  const clusterStyle = olHelper.createClusterStyle(false, false, false);
  const clusterSelectedStyle = olHelper.createClusterStyle(true, false, false);
  const clusterHoverStyle = olHelper.createClusterStyle(true, true, false);
  const clusterBlinkStyle = olHelper.createClusterStyle(false, false, true);
  const clusters = new VectorLayer({
    source: clusterSource,
    style: clusterStyle
  });

  const featureTypes = ref(null);
  const drawStyles = ref(null);
  const selectedDrawStyle = ref(null);

  const mapObjectId = ref(null);
  const mapObject = ref(null);
  const mapSourceId = ref(null);
  const mapSource = ref(null);
  const layers = ref(null);

  const isLoading = ref(false);
  const isFullScreen = ref(false);
  const isShowNames = ref(false);

  watch(isShowNames, function (value) {
    app.$storages.local.set(MAP_SHOW_NAMES, value);
    olHelper.isShowNames = isShowNames.value;
    olHelper.redraw();
  });
  watch(selectedMonitoringObject, (value, oldValue) => {
    if (selectedMapObjectsIniting) {
      return;
    }
    selectedMapObjects.value = [];
    if (value && layers.value) {
      const mapObjects = findObjectsByMonitoringObject(value);
      for (let i = 0; i < mapObjects.length; i++) {
        mapObjects[i].isSelected = true;
      }
    }
  });
  watch(selectedMapObjects, (value, oldValue) => {
    try {
      const count = oldValue && oldValue.length || 0;
      selectedMapObjectsIniting = true;
      let last = null;
      for (let i = value.length - 1; i >= 0; i--) {
        const current = findMapObject(value[i]);
        if (last == null) {
          last = current;
        } else if (last.monitoringObject.id !== current.monitoringObject.id) {
          value.splice(i, 1);
        }
      }
      if (last == null) {
        if (count) {
          selectedMonitoringObject.value = null;
        }
      } else if (selectedMonitoringObject.value !== last.monitoringObject.id) {
        selectedMonitoringObject.value = last.monitoringObject.id;
      }
    } finally {
      setTimeout(() => {
        selectedMapObjectsIniting = false
      }, 0);
    }
  }, {
    deep: true
  });

  async function initFromMapObject(id) {
    clear();

    if (!id) {
      return;
    }

    try {
      isLoading.value = true;

      featureTypes.value = await app.$http.getListItems('/api/v1/core/catalog', {
        q: {
          ref_catalog_type: 'CatalogType:FeatureType'
        }
      });

      await loadSettings();

      const mo = await app.$http.getItem(`/api/v1/iagentservice/monitoringobject/${id}`);
      const ms = (mo && mo.ref_mapsource && mo.ref_mapsource.id)
        ? await app.$http.getItem(`/api/v1/mapsservice/mapsource/${mo.ref_mapsource.id}`)
        : null;

      if (!mo || !ms) {
        return;
      }

      await createBackground(ms);
      await createMapObjects(ms);

      clusters.setZIndex(3);
      olHelper.map.addLayer(clusters);

      let cur = [];
      let curC = null;
      olHelper.map.on('pointermove', (e) => {
        if (cur.length) {
          cur.map((f) => f.isHover = false);
          cur = [];
          tooltip.value = null;
        }
        if (curC) {
          curC.setStyle(clusterStyle)
          curC = null;
        }

        olHelper.map.forEachFeatureAtPixel(e.pixel, (f) => {
          if (f.get('features') && f.get('features').length) {
            f.setStyle(clusterHoverStyle);
            curC = f;
            return true;
          }
          cur = findObjectsByMapObject(f.get('data_id'));
          cur.map((c) => {
            if (!c.isSelected) {
              tooltip.value = {
                x: e.pixel[0],
                y: e.pixel[1],
                message: c.monitoringObject.name
              };
              c.isHover = true;
            }
          })
          return true;
        });
        olHelper.map.getTargetElement().style.cursor = olHelper.map.hasFeatureAtPixel(e.pixel)
          ? 'pointer'
          : '';
      });
      olHelper.map.on('moveend', () => {
        group.value = null;
      });
      olHelper.map.on('movestart', () => {
        group.value = null;
      });
      olHelper.map.on('dblclick', (e) => {
        tooltip.value = null;
        let find = false;
        olHelper.map.forEachFeatureAtPixel(e.pixel, async (f) => {
          let features = findObjectsByMapObject(f.get('data_id'));
          if (features && features.length) {
            if (readOnly) {
              let passport = await app.$http.getListFirstItem(
                `/api/v1/passportobjectservice/passportobject?q=ref_monitoring_object__eq__${features[0].monitoringObject.id}`
              );
              if (passport) {
                passport = await app.$http.getItem(`/api/v1/passportobjectservice/passportobject/${passport.id}`);
              }
              let showMap = async (mos, passport) => {
                if (!mos.length) {
                  if (passport) {
                    let { x, y } = e.target.getViewport().getBoundingClientRect();
                    let r = await app.$boxes.selectItem({
                      activator: {
                        left: `${e.pixel[0] + 8}px`,
                        top: `${e.pixel[1] + 8}px`
                      },
                      parent,
                      zIndex: 1,
                      overlayZIndex: 0,
                      settings: {
                        items: [
                          {
                            id: passport.id,
                            name: 'Паспорт объекта',
                            params: {
                              icon: 'mdi-information-box-outline'
                            },
                            passport
                          }
                        ],
                        label: 'name',
                        icon: 'mdi-earth'
                      }
                    });
                    if (r && r.id) {
                      if (r.passport) {
                        app.$eventBuss.clientEmit('SHOW_PASSPORT', {
                          passport: r.passport,
                          id: mapObjectId.value
                        });
                      } else {
                        await initFromMapObject(r.id);
                      }
                    }
                  }
                  return;
                }
                let momss = mos.filter((mo) => !!(mo.ref_mapsource && mo.ref_mapsource.id));
                if (momss.length === 1 && !passport) {
                  await initFromMapObject(momss[0].id);
                  return;
                }
                if (momss.length) {
                  let { x, y } = e.target.getViewport().getBoundingClientRect();
                  let r = await app.$boxes.selectItem({
                    activator: {
                      left: `${e.pixel[0] + 8}px`,
                      top: `${e.pixel[1] + 8}px`
                    },
                    zIndex: 1,
                    overlayZIndex: 0,
                    parent,
                    settings: {
                      items: [
                        ...(passport ? [{
                          id: passport.id,
                          name: 'Паспорт объекта',
                          params: {
                            icon: 'mdi-information-box-outline'
                          },
                          passport
                        }] : []),
                        ...momss
                      ],
                      label: 'name',
                      icon: 'mdi-earth'
                    }
                  });
                  if (r && r.id) {
                    if (r.passport) {
                      app.$eventBuss.clientEmit('SHOW_PASSPORT', {
                        passport: r.passport,
                        id: mapObjectId.value
                      });
                    } else {
                      await initFromMapObject(r.id);
                    }
                  }
                } else {
                  let ids = mos.map((m) => `'${m.id}'`).join(',')
                  mos = await app.$http.getListItems(
                    `/api/v1/iagentservice/monitoringobject?q=ref_parents__in__[${ids}]&attrs=id,name,ref_mapsource&sorting=name%1`
                  );
                  await showMap(mos, passport);
                }
              }
              await showMap([features[0].monitoringObject], passport);
            } else {
              features.map((f) => f.isSelected = true);
              find = true;
            }
          }
          return true;
        });
        if (!readOnly && !find) {
          selectedMapObjects.value = [];
        }
      });
      olHelper.map.on('singleclick', (e) => {
        group.value = null;
        let find = false;
        olHelper.map.forEachFeatureAtPixel(e.pixel, (f) => {
          if (curC && curC !== f) {
            curC.setStyle(clusterStyle)
            curC = null;
          }

          if (f.get('features') && f.get('features').length > 1) {
            f.setStyle(clusterSelectedStyle);
            curC = f;
            group.value = {
              x: e.pixel[0],
              y: e.pixel[1],
              features: f.get('features').map((ff) => {
                return {
                  id: ff.get('data_id'),
                  name: ff.get('name'),
                  src: ff.get('src'),
                  color: ff.get('color'),
                  blink: ff.get('blink')
                }
              })
            }
            return true;
          }

          if (readOnly) {
            let features = findObjectsByMapObject(f.get('data_id'));
            if (features && features.length) {
              features.map((f) => f.isSelected = true);
              find = true;
            }
          } else {
            let feature = findMapObject(f.get('data_id'));
            if (feature) {
              feature.isSelected = true;
              find = true;
            }
          }
          return true;
        });
        if (!find) {
          selectedMapObjects.value = [];
        }
      });

      mapObjectId.value = mo.id;
      mapObject.value = mo;
      mapSourceId.value = ms.id;
      mapSource.value = ms;

    } finally {
      goHome();
      isLoading.value = false;
    }
  }
  async function initFromMapSource(id) {
    clear();

    if (!id) {
      return;
    }

    try {
      isLoading.value = true;

      await loadSettings();

      const ms = await app.$http.getItem(`/api/v1/mapsservice/mapsource/${id}`);

      if (!ms) {
        return;
      }

      await createBackground(ms);

      mapSourceId.value = ms.id;
      mapSource.value = ms;

    } finally {
      fitToScreen();
      isLoading.value = false;
    }
  }
  function clear() {
    olHelper.deleteMap();

    hasAlarm.value = false;
    tooltip.value = null;
    featureTypes.value = null;
    drawStyles.value = null;

    mapObjectId.value = null;
    mapObject.value = null;
    mapSourceId.value = null;
    mapSource.value = null;
    layers.value = null;

    selectedMapObjects.value = [];
    selectedMonitoringObject.value = null;

    blinkObjects = [];

    group.value = null;

    clusterVectorSource.clear(true);

    if (blinkInterval) {
      clearInterval(blinkInterval);
      blinkInterval = null;
    }
  }
  async function loadSettings() {
    isShowNames.value = !!app.$storages.local.get(MAP_SHOW_NAMES);
    olHelper.isShowNames = isShowNames.value;
  }
  async function getFileObject(fileObject) {
    let result = !!(fileObject && fileObject.id)
      ? await app.$http.getItem(`/api/v1/fileservice/fileobject/${fileObject.id}`)
      : null;
    if (result) {
      result.extension = (result.extension || '').toString().toLowerCase();
    }
    return result;
  }
  async function createBackground(mapSource) {
    olHelper.createMap();
    if (mapSource.url) {
      await olHelper.createTileBackground(mapSource.url);
    } else {
      await createBackgroundItem(mapSource, mapSource.ref_background);
      await createBackgroundItem(mapSource, mapSource.ref_fileobject);
    }
    if (!readOnly) {
      olHelper.createIterations(onFeaturesChanged);
    }
    olHelper.bootstrap(id.value);
  }
  async function createBackgroundItem(mapSource, fileObject) {
    fileObject = await getFileObject(fileObject);
    if (fileObject) {
      if (fileObject.extension === '.svg') {
        await olHelper.createSvgBackground(
          fileObject.download_url,
          mapSource.width,
          mapSource.height
        );
      } else {
        await olHelper.createImageBackground(
          fileObject.download_url,
          mapSource.width,
          mapSource.height
        );
      }
    }
  }
  async function createMapObjects(mapSource) {
    const monitoringObjects = await app.$http.getListItems(`/api/v1/iagentservice/monitoringobject/mapsource/${mapSource.id}`);
    const mapObjects = await app.$http.getListItems(`/api/v1/mapsservice/mapobject/all?q=ref_mapsource__eq__${mapSource.id}`);
    for (let i = 0; i < monitoringObjects.length; i++) {
      monitoringObjects[i].mapObjects = mapObjects.filter((o) => o.ref_monitoring_object.id === monitoringObjects[i].id);
    }
    for (let i = 0; i < monitoringObjects.length; i++) {
      await createMapObject(monitoringObjects[i]);
    }
  }
  async function createMapObject(monitoringObject) {
    const mapObjects = monitoringObject.mapObjects || [];
    if (!mapObjects.length) {
      return;
    }
    for (let i = 0; i < mapObjects.length; i++) {
      const coords = mapObjects[i].coords;
      const drawStyleId = mapObjects[i].ref_draw_style && mapObjects[i].ref_draw_style.id;
      if (!coords || !drawStyleId) {
        continue;
      }
      const layer = await getLayer(drawStyleId);
      if (!layer) {
        continue;
      }

      let feature = layer.createFeature(monitoringObject, mapObjects[i], layer.drawStyle);
      feature.set('isCustom', true)
      if (feature) {
        let obj = await createMapObjectWrapper(monitoringObject, mapObjects[i], feature, layer);
        obj.isBlink = !!(blinkOnAlarm && monitoringObject.status && monitoringObject.status.alarm_flag);
        if (layer.featureType === 'FeatureType:Point' && hideGroups !== true) {
          clusterVectorSource.addFeature(feature);
        } else {
          layer.source.addFeature(feature);
        }

        layer.mapObjects.push(obj);
      }
    }
  }
  async function createMapObjectWrapper(monitoringObject, mapObject, feature, layer) {
    const isSelected = computed({
      get: () => selectedMapObjects.value.indexOf(mapObject.id) >= 0,
      set: (value) => {
        let index = selectedMapObjects.value.indexOf(mapObject.id);
        if (index >= 0) {
          selectedMapObjects.value.splice(index, 1);
        }
        if (value) {
          selectedMapObjects.value.push(mapObject.id);
        }
      }
    });
    const isHover = ref(false);
    const isBlink = ref(false);

    const watchStyles = () => {
      if (isBlink.value) {
        feature.setStyle(layer.blinkStyle);
        feature.set('blink', 3);
      } else {
        feature.set('blink', 0);
        if (isSelected.value) {
          feature.setStyle(layer.selectedStyle);
        } else if (isHover.value) {
          feature.setStyle(layer.hoverStyle);
        } else {
          feature.setStyle(layer.style);
        }
      }
    }
    const watchSelection = () => {
      if (readOnly) {
        return;
      }
      if (isSelected.value) {
        if (!(feature.getGeometry() instanceof Point) && olHelper.modifiedFeatures) {
          olHelper.modifiedFeatures.push(feature);
        }
        if (olHelper.translatedFeatures) {
          olHelper.translatedFeatures.push(feature);
        }
      } else {
        if (olHelper.modifiedFeatures) {
          olHelper.modifiedFeatures.remove(feature);
        }
        if (olHelper.translatedFeatures) {
          olHelper.translatedFeatures.remove(feature);
        }
      }
    }

    watch([isSelected, isHover, isBlink], watchStyles);
    watch(isSelected, watchSelection);

    watch(isBlink, (value) => {
      if (value) {
        addBlink(feature);
      } else {
        removeBlink(feature);
      }
    });

    watchStyles();
    watchSelection();

    return reactive({
      id: mapObject.id,
      featureType: computed(() => layer.featureType),
      mapObject,
      monitoringObject,
      feature,
      isSelected,
      isHover,
      isBlink,
      data: mapObject
    });
  }
  async function onFeaturesChanged(features) {
    for (let i = 0; i < features.length; i++) {
      let feature = features[i];
      let coords = feature.getGeometry().getCoordinates();
      let obj = findMapObject(feature.get('data_id'));
      if (obj) {
        switch (obj.featureType) {
          case 'FeatureType:Point':
            obj.data.coords.point = coords;
            break;
          case 'FeatureType:Line':
            obj.data.coords.points = coords;
            break;
          case 'FeatureType:Polygon':
            obj.data.coords.points = coords[0];
            break;
        }
        await app.$http.put(`/api/v1/mapsservice/mapobject/${obj.id}`, obj.data);
      }
    }
  }

  async function getDrawStyle(drawStyleId) {
    if (!featureTypes.value) {
      return null;
    }
    if (!drawStyles.value) {
      drawStyles.value = [];
    }
    let result = drawStyles.value.find((s) => s.id === drawStyleId);
    if (!result) {
      result = await app.$http.getItem(`/api/v1/mapsservice/drawstyle/${drawStyleId}`);
      result.ref_feature_type = featureTypes.value.find((f) => f.id === result.ref_feature_type.id);
      if (result.ref_feature_type) {
        drawStyles.value.push(result);
      }
    }
    return result;
  }
  async function getLayer(drawStyleId) {
    if (!layers.value) {
      layers.value = [];
    }
    let layer = layers.value.find((l) => l.drawStyleId === drawStyleId);
    if (!layer) {
      layer = await createLayerWrapper(drawStyleId);
      if (layer) {
        layers.value.push(layer);
      }
    }
    return layer;
  }
  function createDefaultPointCoords(screenX, screenY) {
    return {
      point: olHelper.map.getCoordinateFromPixel([screenX, screenY])
    };
  }
  function createDefaultLineCoords(screenX, screenY) {
    return {
      points: [
        olHelper.map.getCoordinateFromPixel([screenX - 50, screenY - 20]),
        olHelper.map.getCoordinateFromPixel([screenX, screenY]),
        olHelper.map.getCoordinateFromPixel([screenX + 50, screenY - 20])
      ]
    };
  }
  function createDefaultPolygonCoords(screenX, screenY) {
    return {
      points: [
        olHelper.map.getCoordinateFromPixel([screenX - 50, screenY - 50]),
        olHelper.map.getCoordinateFromPixel([screenX - 50, screenY + 50]),
        olHelper.map.getCoordinateFromPixel([screenX + 50, screenY + 50]),
        olHelper.map.getCoordinateFromPixel([screenX + 50, screenY - 50])
      ]
    };
  }
  function createPolygonFeature(monitoringObject, mapObject, drawStyle) {
    if (!monitoringObject || !mapObject || !mapObject.coords || !mapObject.coords.points) {
      return null;
    }
    return olHelper.createPolygonFeature(monitoringObject, mapObject, drawStyle);
  }
  function createLineFeature(monitoringObject, mapObject, drawStyle) {
    if (!monitoringObject || !mapObject || !mapObject.coords || !mapObject.coords.points) {
      return null;
    }
    return olHelper.createLineFeature(monitoringObject, mapObject, drawStyle);
  }
  function createPointFeature(monitoringObject, mapObject, drawStyle) {
    if (!monitoringObject || !mapObject || !mapObject.coords || !mapObject.coords.point) {
      return null;
    }
    return olHelper.createPointFeature(monitoringObject, mapObject, drawStyle);
  }
  async function createLayerWrapper(drawStyleId) {
    const drawStyle = await getDrawStyle(drawStyleId);
    if (!drawStyle) {
      return null;
    }
    const featureType = drawStyle.ref_feature_type;
    const isVisible = ref(true);

    let style = null;
    let selectedStyle = null;
    let hoverStyle = null;
    let blinkStyle = null;
    let createDefaultCoords = null
    let createFeature = null;
    let zIndex = 0;
    switch (featureType.sys_name) {
      case 'FeatureType:Polygon':
        style = olHelper.createPolygonStyle(false, false, false);
        selectedStyle = olHelper.createPolygonStyle(true, false, false);
        hoverStyle = olHelper.createPolygonStyle(true, true, false);
        blinkStyle = olHelper.createPolygonStyle(false, false, true);
        createDefaultCoords = createDefaultPolygonCoords;
        createFeature = createPolygonFeature;
        zIndex = 1;
        break;
      case 'FeatureType:Line':
        style = olHelper.createLineStyle(false, false, false);
        selectedStyle = olHelper.createLineStyle(true, false, false);
        hoverStyle = olHelper.createLineStyle(true, true, false);
        blinkStyle = olHelper.createLineStyle(false, false, true);
        createDefaultCoords = createDefaultLineCoords;
        createFeature = createLineFeature;
        zIndex = 2;
        break;
      case 'FeatureType:Point':
        style = olHelper.createPointStyle(false, false, false);
        selectedStyle = olHelper.createPointStyle(true, false, false);
        hoverStyle = olHelper.createPointStyle(true, true, false);
        blinkStyle = olHelper.createPointStyle(false, false, true);
        createDefaultCoords = createDefaultPointCoords;
        createFeature = createPointFeature;
        zIndex = 5;
        break;
    }
    if (!style) {
      return null;
    }

    const source = new VectorSource();
    const layer = new VectorLayer({
      source,
      style
    });
    layer.setZIndex(zIndex);
    const mapObjects = [];

    watch(isVisible, (value) => {
      layer.setVisible(value);
      updateCluster();
    });

    olHelper.map.addLayer(layer);

    return Object.seal(reactive({
      name: computed(() => (drawStyle.name || '').trim() || `$t.core.common.noName`),
      isVisible,
      drawStyleId: computed(() => drawStyle.id),
      drawStyle: computed(() => drawStyle),
      featureType: computed(() => featureType.sys_name),
      style: computed(() => style),
      selectedStyle: computed(() => selectedStyle),
      hoverStyle: computed(() => hoverStyle),
      blinkStyle: computed(() => blinkStyle),
      source: computed(() => source),
      layer: computed(() => layer),
      mapObjects: computed(() => mapObjects),
      createDefaultCoords,
      createFeature
    }));
  }
  async function reloadMonitoringObject(id) {
    if (mapObjectId.value && findMonitoringObject(id)) {
      const monitoringObject = await app.$http.getItem(`/api/v1/iagentservice/monitoringobject/${id}`);
      if (mapObjectId.value === id) {
        let msId = monitoringObject.ref_mapsource && monitoringObject.ref_mapsource.id;
        if (msId !== mapSourceId) {
          return await initFromMapObject(id);
        }
      }
      const mapObjects = findObjectsByMonitoringObject(id);
      const color = monitoringObject.status && monitoringObject.status.color;
      const isBlink = !!(monitoringObject.status && monitoringObject.status.alarm_flag);
      for (let i = 0; i < mapObjects.length; i++) {
        mapObjects[i].feature.set('color', color);
        mapObjects[i].isBlink = blinkOnAlarm && isBlink;
        if (group.value) {
          let feature = group.value.features.find((f) => f.id === mapObjects[i].id);
          if (feature) {
            feature.color = mapObjects[i].feature.get('color');
          }
        }
      }
    }
  }

  async function redraw() {
    if (layers.value) {
      for (let i = 0; i < layers.value.length; i++) {
        let layer = layers.value[i];
        for (let j = 0; j < layer.mapObjects.length; j++) {
          layer.source.removeFeature(layer.mapObjects[j].feature);
        }
        layer.mapObjects.splice(0, layer.mapObjects.length);
      }
      await createMapObjects(mapSource.value);
    }
  }

  function findObjectsByMapObject(id) {
    let mo = null;
    if (layers.value) {
      for (let i = 0; i < layers.value.length; i++) {
        let layer = layers.value[i];
        let object = layer.mapObjects.find((o) => o.id === id);
        if (object) {
          mo = object.monitoringObject;
          break;
        }
      }
    }
    return mo ? findObjectsByMonitoringObject(mo.id) : [];
  }
  function findMonitoringObject(id) {
    if (layers.value) {
      for (let i = 0; i < layers.value.length; i++) {
        let layer = layers.value[i];
        let object = layer.mapObjects.find((o) => o.monitoringObject.id === id);
        if (object) {
          return object.monitoringObject;
        }
      }
    }
    return null;
  }
  function findMapObject(id) {
    if (layers.value) {
      for (let i = 0; i < layers.value.length; i++) {
        let layer = layers.value[i];
        let object = layer.mapObjects.find((o) => o.id === id);
        if (object) {
          return object;
        }
      }
    }
    return null;
  }
  function findObjectsByMonitoringObject(id) {
    let result = [];
    if (layers.value) {
      for (let i = 0; i < layers.value.length; i++) {
        let layer = layers.value[i];
        let objects = layer.mapObjects.filter((o) => o.monitoringObject.id === id);
        if (objects.length) {
          result.push(...objects);
        }
      }
    }
    return result;
  }
  async function removeMapObjects() {
    if (readOnly) {
      return;
    }

    if (!mapObjectId.value || isLoading.value || !selectedMapObjects.value.length) {
      return;
    }
    for (let i = 0; i < selectedMapObjects.value.length; i++) {
      await app.$http.delete(`/api/v1/mapsservice/mapobject/${selectedMapObjects.value[i]}`);
    }

    removeFromMap(selectedMapObjects.value);
  }
  function removeFromMap(ids) {
    for (let i = 0; i < ids.length; i++) {
      for (let j = 0; j < layers.value.length; j++) {
        let layer = layers.value[j];
        let object = layer.mapObjects.find((o) => o.id === ids[i]);
        if (object) {
          layer.source.removeFeature(object.feature);
          clusterVectorSource.removeFeature(object.feature);
          let index = layer.mapObjects.indexOf(object);
          layer.mapObjects.splice(index, 1);
        }
      }
      if (group.value) {
        let feature = group.value.features.find((f) => f.id === ids[i]);
        if (feature) {
          let index = group.value.features.indexOf(feature);
          group.value.features.splice(index, 1);
        }
      }
    }
    selectedMapObjects.value = [];
  }
  async function addMapObject(monitoringObjectId, screenX, screenY) {
    if (!mapObjectId.value || isLoading.value || !selectedDrawStyle.value) {
      return;
    }
    let monitoringObject = findMonitoringObject(monitoringObjectId);
    if (!monitoringObject) {
      monitoringObject = await app.$http.getItem(`/api/v1/iagentservice/monitoringobject/${monitoringObjectId}`);
      monitoringObject.mapObjects = monitoringObject.mapObjects.filter((mo) => mo.ref_mapsource.id === mapSource.value.id);
    }
    let layer = await getLayer(selectedDrawStyle.value.id);
    const coords = layer.createDefaultCoords(screenX, screenY);

    const mapObject = await app.$http.post(`/api/v1/mapsservice/mapobject`, {
      coords,
      ref_draw_style: {
        id: selectedDrawStyle.value.id
      },
      ref_mapsource: {
        id: mapSourceId.value
      },
      ref_monitoring_object: {
        id: monitoringObjectId
      }
    });

    let feature = layer.createFeature(monitoringObject, mapObject, layer.drawStyle);
    if (feature) {
      let obj = await createMapObjectWrapper(monitoringObject, mapObject, feature, layer);
      if (layer.featureType === 'FeatureType:Point') {
        clusterVectorSource.addFeature(feature);
      } else {
        layer.source.addFeature(feature);
      }
      layer.mapObjects.push(obj);
      obj.isSelected = true;
    }
  }

  function fitToScreen() {
    if (!mapSource.value) {
      return;
    }
    const { url, width, height } = mapSource.value;
    if (!url && width && height) {
      olHelper.fit(0, 0, width, height);
    } else {
      olHelper.setView(0, 0, 0);
    }
  }
  function goHome() {
    const view = mapObject.value && mapObject.value.geo && mapObject.value.geo.view || {};
    const { x, y, zoom } = view;
    if (x != null && y != null && zoom != null) {
      olHelper.setView(x, y, zoom);
    } else {
      fitToScreen();
    }
  }
  async function setHome() {
    if (mapObjectId.value) {
      let view = olHelper.getView();
      mapObject.value = await app.$http.put(`/api/v1/iagentservice/monitoringobject/${mapObjectId.value}/view`, view);
    }
  }
  async function clearHome() {
    if (mapObjectId.value) {
      mapObject.value = await app.$http.delete(`/api/v1/iagentservice/monitoringobject/${mapObjectId.value}/view`);
    }
  }
  async function loadMapSourceViewList() {
    return await app.$http.getListItems('/api/v1/mapsservice/mapsourceview?sorting=name%1');
  }
  async function createMapSourceView(name) {
    let url = olHelper.getView();
    url.mapObject = {
      id: mapObject.value.id,
      name: mapObject.value.name
    }
    await app.$http.post('/api/v1/mapsservice/mapsourceview', {
      name,
      url: JSON.stringify(url)
    });
  }
  async function removeMapSourceView(mapSourceViewId) {
    await app.$http.delete(`/api/v1/mapsservice/mapsourceview/${mapSourceViewId}`);
  }
  async function openMapSourceView(mapSourceViewId) {
    let mapSourceView = await app.$http.getItem(`/api/v1/mapsservice/mapsourceview/${mapSourceViewId}`);
    let url = JSON.parse(mapSourceView.url);
    if (mapObject.value == null || mapObject.value.id !== url.mapObject.id) {
      await initFromMapObject(url.mapObject.id);
    }
    olHelper.setView(url.x, url.y, url.zoom);
  }
  function zoomIn() {
    olHelper.setZoom(ZOOM_RATE);
  }
  function zoomOut() {
    olHelper.setZoom(-ZOOM_RATE);
  }
  function toggleFullscreen() {
    isFullScreen.value = !isFullScreen.value;
    setTimeout(fitToScreen);
  }
  function showOnMap(monitoringObjectId, color) {
    let objs = findObjectsByMonitoringObject(monitoringObjectId);
    if (objs && objs[0]) {
      if (color) {
        for (let i = 0; i < objs.length; i++) {
          objs[i].isBlink = true;
          objs[i].feature.set('extraColor', color);
        }
      }
      for (let i = 0; i < objs.length; i++) {
        objs[i].isSelected = true;
      }
      let fullExtent = objs[0].feature.getGeometry().getExtent();
      for (let i = 1; i < objs.length; i++) {
        extend(fullExtent, objs[i].feature.getGeometry().getExtent());
      }
      let x = fullExtent[0] + (fullExtent[2]-fullExtent[0])/2;
      let y = fullExtent[1] + (fullExtent[3]-fullExtent[1])/2;
      let { zoom } = olHelper.getView();
      olHelper.setView(x, y, zoom);

      updateCluster();
    }
  }
  function selectMapObject(id) {
    let features = findObjectsByMapObject(id);
    if (features && features.length) {
      features.map((f) => f.isSelected = true);
    }
  }
  function updateCluster() {
    clusterVectorSource.clear(true);
    for (let i = 0; i < layers.value.length; i++) {
      let layer = layers.value[i];
      if (layer.featureType === 'FeatureType:Point' && layer.isVisible) {
        clusterVectorSource.addFeatures(layer.mapObjects.map(({feature}) => feature));
      }
    }
  }

  /**
   * Функция для получения снимка карты в виде файла, blob или base64
   * @param isBase64 - Флаг указывающий что результат должен быть в виде Base64.
   * @param isBlob - Флаг указывающий что результат должен быть в виде Blob.
   * @returns {null|Blob|Base64}
   */
  async function getScreenshot({ isBlob, isBase64 } = { isBlob: false, isBase64: false }) {
    try {
      const result = await olHelper.getScreenshot({ isBlob, isBase64 });
      return result;
    } catch(err) {
      return null;
    }
  }

  return Object.seal(reactive({
    id: computed(() => id.value),
    isLoading: computed(() => isLoading.value),
    isFullScreen: computed(() => isFullScreen.value),
    isShowNames,
    hasHome: computed(() => !!(mapObject.value && mapObject.value.geo && mapObject.value.geo.view)),
    mapObjectId: computed(() => mapObjectId.value),
    mapObject: computed(() => mapObject.value),
    mapSourceId: computed(() => mapSourceId.value),
    layers: computed(() => layers.value),
    selectedDrawStyle,
    selectedMapObjects,
    selectedMonitoringObject,
    hasAlarm,
    clear,
    initFromMapObject,
    initFromMapSource,
    fitToScreen,
    goHome,
    setHome,
    clearHome,
    zoomIn,
    zoomOut,
    toggleFullscreen,
    loadMapSourceViewList,
    createMapSourceView,
    removeMapSourceView,
    openMapSourceView,
    showOnMap,
    tooltip,
    group,
    getDrawStyle,
    createMapObject,
    findObjectsByMapObject,
    findObjectsByMonitoringObject,
    removeFromMap,
    reloadMonitoringObject,
    addMapObject,
    removeMapObjects,
    selectMapObject,
    olHelper,
    getScreenshot
  }));
}

export default createEvaMapWrapper;
