<template>
  <eva-input
    :value="!!currentValue"
    :label="label"
    :sublabel="sublabel"
    :sublabelColor="sublabelColor"
    :placeholder="isFocus ? '' : placeholder"
    :maxlength="maxlength"
    :icon="icon"
    :clearable="clearable"
    :readonly="readonly"
    :preview="preview"
    :depth="depth"
    :error="error"
    @clear="currentValue = ''"
    class="eva-textarea"
  >
    <template v-slot:header>
      <slot name="header"></slot>
    </template>
    <textarea
      ref="input"
      :value="currentValue != null ? currentValue : ''"
      :readonly="readonly || preview"
      :maxlength="maxlength"
      class="eva-textarea__field"
      :style="inputStyle"
      @focus="onFocus"
      @blur="onBlur"
      @input="onInput"
    ></textarea>
  </eva-input>
</template>

<script>
import EvaInputMixin from "./EvaInputMixin";

export default {
  name: 'eva-textarea',

  mixins: [
    EvaInputMixin
  ],

  props: {
    autoHeight: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      isFocus: false,
      inputHeight: 48
    }
  },

  computed: {
    inputStyle() {
      return this.autoHeight ? {
        overflow: true,
        resize: 'none',
        height: this.inputHeight ? `${this.inputHeight}px` : 'auto'
      } : {};
    }
  },

  methods: {
    onIconClick(e) {
      this.$emit('icon-click', e);
    },
    onFocus() {
      this.isFocus = true;
    },
    onBlur() {
      this.isFocus = false;
    },
    onInput(e) {
      this.currentValue = e.target.value;
      this.invalidateHeight();
    },
    invalidateHeight() {
      this.inputHeight = null;
      this.$nextTick(() => {
        let value = this.$refs.input.scrollHeight;
        this.inputHeight = value < 48
          ? 48
          : value;
      });
    }
  },
  mounted() {
    this.invalidateHeight();
  }
}
</script>

<style lang="less">
.eva-textarea {
  height: unset;
  .eva-textbox__input {
    height: unset;
    overflow: hidden;
  }
}
</style>
