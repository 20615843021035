<template>
  <div class="eva-video eva-background-2" ref="evaVideo">
    <div
      v-show="isShowArchivePanels"
      class="eva-video__aside-panel eva-border-right">

      <div class="eva-video__aside-panel-date eva-border-bottom eva-background-2">
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          attach
          :disabled="disabled"
        >
          <template v-slot:activator="{ on, attrs }">
            <eva-input
              :value="!!dateFormatted"
              icon="mdi-calendar"
              v-bind="attrs"
              v-on="on"
              @icon-click="on.click"
            >
              <div class="eva-textbox__input">
                {{ dateFormatted }}
              </div>
            </eva-input>
          </template>
          <v-date-picker
            :locale="$locale.current"
            :first-day-of-week="1"
            :max="currentDate"
            v-model="date"
            no-title
            @input="menu = false"
            @change="setDate"
          />
        </v-menu>
      </div>

      <eva-table
        class="eva-video__aside-panel-list"
        :settings="tableSettings"
        v-model="selected"
        :value-alt="archiveData"
        ref="table"
      >
        <template v-slot:item.name="{ item }">
          <div style="text-align: center">
            <span>{{ `${item.name}` }}</span>
<!--            <span style="margin-left: 2px; color: #FFC107">{{ `${(item.timelength / 1000).toFixed(0)} сек.` }}</span>-->
          </div>
        </template>
      </eva-table>
    </div>

    <div class="eva-video__main">
      <div class="eva-video__main-container" ref="mainContainer">

        <div v-if="error" class="eva-video__error">
          <v-icon large class="mb-3">
            mdi-video-box-off
          </v-icon>
          <span>
            Видео не доступно или отсутствует
          </span>
        </div>
        <video
          v-else
          ref="video"
          class="eva-video__video"
          :style="`object-fit: ${objectFit};`"
          :autoplay="!archive"
          playsinline
          muted
          @click="showElements"
        />

        <div class="eva-video__header-background">
        </div>
        <div class="eva-video__header">
          <div class="eva-video__header-left">
            <v-menu
              :disabled="settings.disabled || disabled"
              v-if="settings.monitoringObjectList && settings.monitoringObjectList.length"
              offset-y
              attach
              @input="onShowMoList"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  text
                  outlined
                  v-bind="attrs"
                  v-on="on"
                  color="white"
                >
                  {{ currentMonitoringObject.name }}
                  <v-icon v-if="!settings.disabled && !archive && !fullscreen">
                    mdi-chevron-down
                  </v-icon>
                </v-btn>
              </template>
              <v-list dense :style="onShowMoListStyle">
                <v-list-item
                  v-for="(monitoringObject, index) in settings.monitoringObjectList"
                  :key="index"
                  @click="setCurrentMonitoringObject(monitoringObject)"
                >
                  <v-list-item-title>{{ monitoringObject.name }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
          <div class="eva-video__header-right">
            <eva-btn
              v-if="!archive && currentMonitoringObject.status && currentMonitoringObject.status.ACT"
              icon="mdi-circle"
              type="icon-text--secondary--small"
              label="Rec"
              tooltip="Ведётся запись"
            />
            <eva-btn
              v-if="!archive && currentMonitoringObject.status && currentMonitoringObject.status.ARM"
              class="ml-2"
              icon="mdi-shield-check"
              type="icon--secondary--small"
              tooltip="На охране"
            />
            <div v-if="!archive" class="eva-video__header-clock ml-2" :id="tempId"></div>
            <eva-btn
              v-if="settings.tuning"
              class="ml-2"
              icon="mdi-close"
              type="icon--secondary--small"
              @click="removeItemFromPanel"
              tooltip="Убрать с панели"
            />
          </div>
        </div>
        <div class="eva-video__footer">
          <div v-if="showButtons || error" class="eva-video__footer-left">
            <eva-btn
              v-if="!archive && !hideArchive"
              icon="mdi-archive"
              type="icon--secondary--small"
              @click="clickOpenArchive"
              tooltip="Архив"
            />
            <eva-btn
              v-if="archive && !hideArchive"
              icon="mdi-video"
              type="icon--secondary--small"
              @click="openOnline()"
              tooltip="Онлайн"
            />
          </div>
          <div v-if="showButtons" class="eva-video__footer-right">
            <eva-btn
              v-if="!error"
              icon="mdi-camera"
              type="icon--secondary--small"
              @click="getScreenshot()"
              tooltip="Сделать снимок"
            />
            <eva-btn
              v-if="!error"
              class="ml-2"
              :icon="sizeIcon"
              type="icon--secondary--small"
              @click="videoSizeHandler()"
              :tooltip="currentTooltip"
            />
            <eva-btn
              v-if="!fullscreen"
              class="ml-2"
              icon="mdi-aspect-ratio"
              type="icon--secondary--small"
              @click="fullscreenHandler()"
              tooltip="Во весь экран"
            />
            <eva-btn
              v-if="fullscreen"
              class="ml-2"
              icon="mdi-fullscreen-exit"
              type="icon--secondary--small"
              @click="fullscreenHandler()"
              tooltip="Выход из полноэкранного режима"
            />
          </div>
        </div>

        <eva-progress v-if="disabled"/>
      </div>

      <div v-show="isShowArchivePanels" class="eva-video__archive-timeline">
        <div class="timeline-list" @click="setCurrentArchiveTime($event)">
          <div class="timeline-list-div"> {{ firstTimePosition }} </div>
          <div class="timeline-list-div"> {{ secondTimePosition }} </div>
          <div class="timeline-list-div"> {{ thirdTimePosition }} </div>
        </div>

        <div class="timeline-marker" :style="`left: ${currentMarkerPosition}px;`" v-tooltip="$eva.$tools.tooltipContent(currentTimelineTooltip)"></div>

        <canvas :id="id" class="eva-video__archive-canvas">
        </canvas>
      </div>

      <div v-show="isShowArchivePanels" class="eva-video__archive-footer eva-background-2">
        <div class="eva-video__archive-footer-left">
          <div>
            Временной диапазон: {{ currentFrame.name }}
          </div>
          <v-slider
            v-model="currentValue"
            step="1"
            max="6"
            ticks="always"
            tick-size="8"
            @mouseup="check_and_go()"
            :disabled="disabled"
          ></v-slider>
        </div>
        <div class="eva-video__archive-footer-middle">
          <span>{{ currentTimelineTooltip }}</span>
          <eva-btn
            v-if="playing"
            icon="mdi-pause"
            type="icon--secondary--small"
            @click="playHandler()"
            tooltip="Пауза"
            :disabled="disabled"
          />
          <eva-btn
            v-if="!playing"
            icon="mdi-play"
            type="icon--secondary--small"
            @click="playHandler()"
            tooltip="Воспроизведение"
            :disabled="disabled"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { v4 as uuid } from 'uuid';
import { cloneDeep, isNil } from 'lodash';
import { computed, nextTick } from 'vue';

import EvaVideoArchive from './EvaVideoArchive.vue';

const CURRENT_DATE = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);

export default {
  name: 'eva-video',

  props: {
    settings: {
      type: Object
    },
    event: {
      type: Object
    },
    hideArchive: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      id: `canvas_${uuid()}`,
      disabled: false,
      menu: false,
      error: false,
      playing: false,
      showButtons: false,
      showPanels: true,
      currentMonitoringObject: {},
      currentStream: {},
      objectFit: null,

      fullscreen: false,

      archive: false,
      archiveType: null,
      incidents: false,
      reports: false,
      firstOpen: true,
      archiveStartTime: null,
      currentArchiveTime: 0,
      selected: null,
      archiveData: null,
      archiveMap: [],
      onShowMoListStyle: null,

      ranges: [
        { id: 30, name: '30 минут' },
        { id: 60, name: '1 час' },
        { id: 120, name: '2 часа' },
        { id: 180, name: '3 часа' },
        { id: 360, name: '6 часов' },
        { id: 720, name: '12 часов' },
        { id: 1440, name: 'Текущий день' }
      ],
      currentFrame: { id: 60, name: '1 час' },
      currentValue: 1,
      frameItems: [],
      millisecondCost: 0,
      startPosition: 0,
      markerPosition: 0,
      steps: 0,
      timerId: null,
      startArchiveTime: 0,
      archiveChannel: null,

      currentDate: CURRENT_DATE,
      date: CURRENT_DATE,
      dateFormatted: moment(CURRENT_DATE).format('DD.MM.YYYY'),

      tempId: uuid(),

      webrtc: null,
      mediaStream: null,
      iceServers: [],

      tableSettings: {
        type: 'drawer',
        prefix: this.$options.name,
        caption: false,
        header: false,
        footer: false,
        tableHeader: false,
        selectable: computed(() => !this.disabled),
        columns: {
          name: {
            type: 'text'
          },
        },
        model: computed(() => [].concat.apply([], this.archiveMap)),
        commands: false
      }
    }
  },

  computed: {
    isShowArchivePanels() {
      return (
        (this.showPanels && this.archive && (!this.archiveType || this.fullscreen)) ||
        (!this.incidents && this.archive && (!this.archiveType || this.fullscreen))
      );
    },
    sizeIcon() {
      switch (this.objectFit) {
        case 'contain':
          return 'mdi-arrow-expand';
        case 'cover':
          return 'mdi-arrow-expand-all';
        case 'fill':
          return 'mdi-arrow-collapse';
      }
    },
    currentTooltip() {
      switch (this.objectFit) {
        case 'contain':
          return 'Подстроить';
        case 'cover':
          return 'Растянуть';
        case 'fill':
          return 'Заполнить';
      }
    },
    firstTimePosition() {
      if (!this.frameItems || !this.frameItems.length || !this.selected) {
        return '';
      }
      return moment.utc(new Date(
        this.frameItems[0].start)
      ).format('HH:mm:ss');
    },
    secondTimePosition() {
      if (!this.frameItems || !this.frameItems.length || !this.selected) {
        return '';
      }
      return moment.utc(
          new Date((this.frameItems[this.frameItems.length - 1].end + this.frameItems[0].start) / 2)
      ).format('HH:mm:ss');
    },
    thirdTimePosition() {
      if (!this.frameItems || !this.frameItems.length || !this.selected) {
        return '';
      }
      return moment.utc(
        new Date(this.frameItems[this.frameItems.length - 1].end - 1000)
      ).format('HH:mm:ss');
    },
    currentTimelineTooltip() {
      if (this.selected && this.selected.id) {
        const timestamp = moment(this.selected.name, 'HH:mm:ss').valueOf();
        return /*this.dateFormatted + */moment(timestamp + this.markerPosition / this.millisecondCost).format('HH:mm:ss');
      }
    },
    currentMarkerPosition() {
      const canvas = document.getElementById(this.id);
      if (canvas && this.markerPosition > canvas.clientWidth - 2) {
        return canvas.clientWidth - 2;
      }
      return this.markerPosition;
    }
  },

  watch: {
    settings: {
      handler() {
        this.currentMonitoringObject = this.settings.monitoringObject;
      },
      deep: true
    },
    async 'settings.archive'() {
      if (this.settings.archiveStartTime) {
        const timeZone = new Date().getTimezoneOffset() * 60000;
        if (timeZone > 0) {
          this.archiveStartTime = this.settings.archiveStartTime - timeZone;
        } else {
          this.archiveStartTime = this.settings.archiveStartTime + Math.abs(timeZone);
        }
        this.date = moment(this.archiveStartTime).format('YYYY-MM-DD');
        this.dateFormatted = moment(this.archiveStartTime).format('DD.MM.YYYY');
      }

      this.archive = this.settings.archive;
      this.showPanels = false;
      if (this.settings.archive && this.settings.incidents) {
        try {
          this.ignoreOnSelect = true;
          await this.openArchive(true);
        } finally {
          this.ignoreOnSelect = false;
        }
      } else if (!this.settings.archive && this.settings.incidents) {
        this.check_and_go();
      }
    },
    event: {
      handler() {
        if (this.event.typeEvent === 'VideoChannel:1') {
          this.playing = false;
        } else if (this.event.typeEvent === 'StatusOMUpdate:1') {
          if (this.event.directObj.id === this.currentMonitoringObject.id) {
            this.$set(this.currentMonitoringObject, 'status', this.event.params);
          }
        }
      }
    },
    selected: {
      async handler(newValue, oldValue) {
        this.startPosition = 0;
        this.markerPosition = 0;
        this.archiveData = null;

        if (this.ignoreOnSelect) {
          return;
        }

        if (newValue == null) {
          this.error = true;
          this.reloadTimeline();
          if (this.timerId) {
            clearInterval(this.timerId);
            this.timerId = null;
          }
          return;
        }
        if (oldValue != null && oldValue.id === newValue.id) {
          return;
        }

        if (!this.disabled) {
          this.firstOpen = false;
          this.disabled = true;
          if (this.archiveChannel) {
            clearInterval(this.timerId);
            this.timerId = null;
            this.startPosition = 0;
            this.markerPosition = 0;
            this.steps = 0;
            this.currentArchiveTime = 0;
            await this.check_and_go();
          } else {
            await this.openArchive(false);
          }
        }
      },
      deep:true
    },
    steps() {
      this.markerPosition = this.startPosition + (this.steps * this.millisecondCost);
    },
    async currentValue() {
      if (!this.disabled) {
        this.currentFrame = this.ranges[this.currentValue];
        this.reloadTimeline();
        clearInterval(this.timerId);
        this.timerId = null;
        this.startPosition = 0;
        this.markerPosition = 0;
        this.steps = 0;
        this.currentArchiveTime = 0;
      }
    }
  },

  async mounted() {
    this.setTime();
    this.archiveType = this.settings.archiveType;
    this.archive = this.settings.archive;
    this.incidents = this.settings.incidents;
    this.reports = this.settings.reports;
    this.showPanels = !this.incidents;

    if (this.settings.archiveStartTime) {
      const timeZone = new Date().getTimezoneOffset() * 60000;
      if (timeZone > 0) {
        this.archiveStartTime = this.settings.archiveStartTime - timeZone;
      } else {
        this.archiveStartTime = this.settings.archiveStartTime + Math.abs(timeZone);
      }
      this.date = moment(this.archiveStartTime).format('YYYY-MM-DD');
      this.dateFormatted = moment(this.archiveStartTime).format('DD.MM.YYYY');
    }

    this.currentMonitoringObject = this.settings.monitoringObject;
    this.objectFit = this.settings.objectFit || 'fill';

    if (!this.archive) {
      await this.check_and_go()
    } else {
      try {
        this.ignoreOnSelect = true;
        await this.openArchive(true);
      } finally {
        this.ignoreOnSelect = false;
      }
    }

    document.addEventListener('fullscreenchange', event => {
      this.fullscreen = !this.fullscreen;
    });
    document.addEventListener('keyup', event => {
      if (this.archive) {
        if (event.code === 'Space') {
          this.playHandler();
        }
      }
    });
    window.addEventListener('resize', event => {
      if (this.archive) {
        let old = this.millisecondCost;
        this.reloadTimeline();
        let d = old / this.millisecondCost;
        this.startPosition = this.startPosition / d;

        this.markerPosition = this.startPosition + (this.steps * this.millisecondCost);
      }
    });
  },

  async beforeDestroy() {
    await this.removeArchiveChannel();
    this.clearVideo();
  },

  methods: {
    async check_and_go(value) {
      const data = value ? cloneDeep(this.archiveData) : cloneDeep(this.selected);
      this.error = false;
      try {
        if (this.archive) {
          if ((this.incidents && this.showPanels) || !this.incidents) {
            this.reloadTimeline();
          }
          if (this.currentArchiveTime !== 0 && this.currentArchiveTime < data.timelength) {
            let time = data.start + this.currentArchiveTime;
            data.begin_time = moment.utc(time).format('YYYY.MM.DD-HH:mm:ss');
          }
          if (this.currentArchiveTime) {
            data.timeout = (data.timelength - this.currentArchiveTime) / 1000;
          } else {
            data.timeout = data.timelength / 1000;
          }
          this.currentStream = await this.$eva.$http.post(`/api/v1/videohubservice/integrationapi/archive/${this.currentMonitoringObject.ref_video_stream.id}`, data);
        } else {
          const videoStream = await this.$eva.$http.get(`/api/v1/videohubservice/videostream/${this.currentMonitoringObject.ref_video_stream.id}`);
          this.currentStream = await this.$eva.$http.get(`/api/v1/videohubservice/integrationapi/${videoStream.ref_channels[0].id}`);
        }

        this.iceServers = [];
        let urls = this.currentStream.ice_servers;
        for (const item of urls) {
          this.iceServers.push({
            urls: item,
            username: this.currentStream.ice_username,
            credential: this.currentStream.ice_credential
          });
        }

        if (!value) {
          if ((this.incidents && this.showPanels) || !this.incidents) {
            if (this.archive && this.firstOpen && data && data.id) {
              await this.$refs.table.$refs.repeater.reload();
              await this.$refs.table.onRowClick(this.$refs.table.$refs.repeater.options, data);
              setTimeout(() => {
                document.getElementById(data.id).scrollIntoView();
              }, 500);
            }
          }
        }
        await this.showVideo(data);
      } catch (error) {
        this.$eva.$logs.error(this.$options.name, 'Во время запуска видео произошла ошибка', error);
        this.error = true;
      } finally {
        this.disabled = false;
      }
    },
    async showVideo(value) {
      this.mediaStream = new MediaStream();
      const video = this.$refs.video;
      video.srcObject = this.mediaStream;
      const webrtc = new RTCPeerConnection({
        iceServers: this.iceServers,
        sdpSemantics: 'unified-plan'
      });
      this.webrtc = webrtc;
      const videoSource = this.currentStream.url;
      webrtc.ontrack = async (event) => {
        if (event.streams && event.streams.length) {
          video.srcObject = event.streams[0];
          let playPromise = video.play();
          if (playPromise !== undefined) {
            playPromise.then(() => {
              this.disabled = false;
            }).catch(error => {
              this.$eva.$logs.error(this.$options.name, 'Во время запуска видео произошла ошибка', error);
              this.disabled = false;
            });
          }
        }
      }

      webrtc.addTransceiver('video', { direction: 'sendrecv' });
      webrtc.onnegotiationneeded = async () => {
        for (let i = 0; i < 5; i++) {
          try {
            this.disabled = true;
            this.$eva.$logs.info(this.$options.name, `Попытка № ${i + 1} открытия канала`);
            const offer = await webrtc.createOffer();
            await webrtc.setLocalDescription(offer);
            const response = await fetch(videoSource, {
              method: 'POST',
              body: new URLSearchParams({data: btoa(webrtc.localDescription.sdp)})
            });
            await webrtc.setRemoteDescription(new RTCSessionDescription({
              type: 'answer',
              sdp: atob(await response.text())
            }));
            this.disabled = false;
            return;
          } catch (error) {
            this.$eva.$logs.error(this.$options.name, 'Во время запуска видео произошла ошибка', error);
          }
        }
        this.error = true;
        this.disabled = false;
      }

      this.webrtcSendChannel = webrtc.createDataChannel('rtsptowebSendChannel');
      this.webrtcSendChannel.onopen = (event) => {
        if (this.archive) {
          nextTick(() => {
            this.playing = true;
            this.startArchiveTime = new Date();
            this.startTimer(value);
          });
        }
      }
      this.webrtcSendChannel.onmessage = (event) => {
        this.$eva.$logs.info(this.$options.name, event.data);
      }
    },
    clearVideo() {
      if (this.webrtc) {
        this.webrtc.close();
        this.webrtc = null;
        this.mediaStream = null;
        this.webrtcSendChannel.close();
      }
    },
    async openArchive(firstOpen, clear) {
      if (this.archiveType && !this.fullscreen) {
        this.$eva.$boxes.show({
          type: this.archiveType,
          width: this.archiveType === 'drawer'? '60%' : '100%',
          header: this.$$t(`Архив: ${this.currentMonitoringObject.name}`),
          component: EvaVideoArchive,
          componentProps: {
            settings: this.settings
          },
          commands: false
        });
      } else {
        if (this.incidents) {
          this.showButtons = false;
        }

        this.archive = true;

        this.firstOpen = firstOpen;
        const data = {
          limit: 0,
          offset: 0
        };

        if (this.firstOpen && this.archiveStartTime) {
          data.date_from = moment(this.archiveStartTime).format('DD-MM-YYYY');
          data.date_to = moment(this.archiveStartTime).format('DD-MM-YYYY');
        } else {
          data.date_from = moment(this.date).format('DD-MM-YYYY');
          data.date_to = moment(this.date).format('DD-MM-YYYY');
        }

        if (clear !== false) {
          this.archiveMap = [];
          await this.removeArchiveChannel();
          await this.$refs.table.$refs.repeater.reload();
          this.reloadTimeline();
          if (this.timerId) {
            clearInterval(this.timerId);
            this.timerId = null;
          }
          this.markerPosition = 0;
        }

        try {
          const { stream_map, id } = await this.$eva.$http.post(`/api/v1/videohubservice/integrationapi/map/${this.currentMonitoringObject.ref_video_stream.id}`, data);
          this.archiveChannel = id;
          if (!stream_map || !stream_map.length) {
            this.error = true;
            return;
          } else {
            this.archiveMap = await cloneDeep(stream_map);
            await this.$refs.table.$refs.repeater.reload();
          }
        } catch (error) {
          this.error = true;
          this.$eva.$boxes.notifyError(this.$$t('Не удалось загрузить архив'), {duration: 10000});
          throw error;
        }

        if (this.firstOpen && this.archiveStartTime) {
          const archiveStartTime = this.archiveStartTime;
          this.selected = cloneDeep(this.archiveMap.reduce(function(prev, curr) {
            return (Math.abs(curr.start - archiveStartTime) < Math.abs(prev.start - archiveStartTime) ? curr : prev);
          }));
        } else if (this.firstOpen && !this.archiveStartTime) {
          this.selected = await cloneDeep(this.archiveMap[0]);
        }

        await this.check_and_go();
      }
    },
    async clickOpenArchive() {
      try {
        this.ignoreOnSelect = true;
        await this.openArchive(true);
      } finally {
        this.ignoreOnSelect = false;
      }
    },
    async openOnline() {
      this.archive = false;
      this.currentDate = CURRENT_DATE;
      this.date = CURRENT_DATE;
      this.dateFormatted = moment(CURRENT_DATE).format('DD.MM.YYYY');
      await this.check_and_go();
    },
    async playHandler() {
      if (this.disabled || !this.$refs.video) {
        return;
      }
      const video = this.$refs.video;
      try {
        this.disabled = true;
        if (this.playing) {
          video.pause();
          let steps = this.steps;
          this.steps = 0;
          this.startPosition += steps * this.millisecondCost;
          this.currentArchiveTime += parseInt(video.currentTime.toString().replace('.', ''));
          this.playing = false;
          await this.$eva.$http.get(`/api/v1/videohubservice/integrationapi/archive/${this.currentMonitoringObject.ref_video_stream.id}`);
        } else {
          await this.check_and_go(this.archiveData);
        }
      } finally {
        this.disabled = false;
      }
    },
    async showElements() {
      this.showButtons = !this.showButtons;
      if (this.incidents && this.archive) {
        this.showPanels = this.showButtons;
        if ((this.incidents && this.showPanels) || !this.incidents) {
          if (this.archive && this.firstOpen && this.selected && this.selected.id) {
            await this.$refs.table.$refs.repeater.reload();
            await this.$refs.table.onRowClick(this.$refs.table.$refs.repeater.options, this.selected);
            setTimeout(() => {
              document.getElementById(this.selected.id).scrollIntoView();
              this.reloadTimeline();
            }, 500);
          }
        }
        if (this.archive && this.firstOpen) this.firstOpen = false;
      }
    },
    videoSizeHandler() {
      switch (this.objectFit) {
        case 'contain':
          this.objectFit = 'cover';
          break
        case 'cover':
          this.objectFit = 'fill';
          break
        case 'fill':
          this.objectFit = 'contain';
          break
      }
    },
    getScreenshot() {
      const link = document.createElement('a');
      const canvas = document.createElement('canvas');
      const video = this.$refs.video;

      canvas.width = 1920;
      canvas.height = 1080;

      let ctx = canvas.getContext('2d');
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

      link.href = canvas.toDataURL('image/jpeg');
      const time = moment(Date.now(), 'x').format('DD.MM.YYYY HH.mm');
      link.download = `${time} - ${this.currentMonitoringObject.name}.jpeg`;
      link.target ="_blank";
      document.body.appendChild(link);
      link.click();
      link.remove();
    },
    async setCurrentMonitoringObject(monitoringObject) {
      if (!this.disabled) {
        if (this.currentMonitoringObject.id !== monitoringObject.id) {
          this.currentMonitoringObject = monitoringObject;
          if (this.archive) {
            await this.setDate();
          } else {
            await this.check_and_go();
          }
        }
      }
    },
    async fullscreenHandler() {
      if (this.fullscreen) {
        document.exitFullscreen();
      } else {
        await this.$refs.evaVideo.requestFullscreen();
      }
    },
    reloadTimeline() {
      const canvas = document.getElementById(this.id);
      if (!canvas) {
        return;
      }
      canvas.width = canvas.clientWidth;
      canvas.height = 21;

      const ctx = canvas.getContext("2d");
      ctx.fillStyle = "#495A6F";

      if (this.selected && this.currentFrame) {
        const endFrameTime = this.selected.start + this.getCurrentFrameLength();
        this.frameItems = [];
        for (const item of this.archiveMap) {
          if (item.start >= this.selected.start && item.end <= endFrameTime) {
            this.frameItems.push(item);
          }
        }
        if (this.frameItems.length) {
          this.millisecondCost = canvas.clientWidth / (this.frameItems[this.frameItems.length - 1].end - this.frameItems[0].start);
          for (const item of this.frameItems) {
            item.w = item.timelength * this.millisecondCost;
            item.x = (item.start - this.selected.start) * this.millisecondCost;
            item.xe = item.x + item.w;
            ctx.fillRect(item.x + 0.5, 0, item.w - 0.5, 21);
          }
        }
      }
    },
    startTimer(value) {
      this.steps = 0;
      const startTimerTime = new Date();
      if (!isNil(this.timerId)) {
        clearInterval(this.timerId);
        this.timerId = null;
      }

      this.timerId = setInterval(async () => {
        if (this.playing) {
          const currentTime = new Date();
          const timeout = this.currentArchiveTime
            ? value.timelength - this.currentArchiveTime
            : value.timelength;
          if ((currentTime - startTimerTime) < timeout) {
            this.steps += 1000;
          } else {
            clearInterval(this.timerId);
            this.timerId = null;
            let data = this.archiveData || value;
            if (data) {
              let index = this.frameItems.indexOf(this.frameItems.find((f) => f.id === data.id));
              if (index >= 0 && index < this.frameItems.length - 1) {
                this.archiveData = this.frameItems[index + 1];
                let timlineX = this.archiveData.x;
                this.disabled = true;
                this.timerId = null;
                this.steps = 0;
                this.startPosition = timlineX;
                this.markerPosition = timlineX;
                const part = this.archiveData.timelength / (this.archiveData.xe - this.archiveData.x);
                this.currentArchiveTime = ((timlineX - this.archiveData.x) * part);
                await this.check_and_go(this.archiveData);
              }
            }
          }
        } else {
          clearInterval(this.timerId);
          this.timerId = null;
        }
      }, 1000);
    },
    async setCurrentArchiveTime(event) {
      if (!this.disabled) {
        this.disabled = true;
        const canvas = document.getElementById(this.id);
        const timlineX = event.clientX - canvas.getBoundingClientRect().x;
        this.archiveData = this.frameItems.find(i => timlineX >= i.x && timlineX <= i.xe);
        if (this.archiveData) {
          clearInterval(this.timerId);
          this.timerId = null;
          this.steps = 0;
          this.startPosition = timlineX;
          this.markerPosition = timlineX;
          const part = this.archiveData.timelength / (this.archiveData.xe - this.archiveData.x);
          this.currentArchiveTime = ((timlineX - this.archiveData.x) * part);
          await this.check_and_go(this.archiveData);
        } else {
          this.disabled = false;
        }
      }
    },
    removeItemFromPanel() {
      this.$emit('panel:removeItem');
    },
    setTime() {
      const clock = document.getElementById(this.tempId);
      setInterval(() => {
        clock.textContent = moment().format('HH:mm:ss');
      }, 100);
    },
    checkSingleDigit(digit) {
      return (digit < 10 ? '0' : '') + digit;
    },
    async removeArchiveChannel() {
      this.archiveChannel = null;
    },
    async setDate() {
      this.dateFormatted = moment(this.date).format('DD.MM.YYYY');
      await this.removeArchiveChannel();
      try {
        this.ignoreOnSelect = true;
        this.selected = null;
        await this.openArchive(true);
      } finally {
        this.ignoreOnSelect = false;
      }
    },
    getCurrentFrameLength() {
      if (!this.currentFrame || !this.selected) {
        return 0;
      }
      let selected = moment(this.selected.name, 'HH:mm:ss');
      let end =
        selected.hours() * 60 * 60 * 1000 +
        selected.minutes() * 60 * 1000 +
        selected.seconds() * 1000 +
        this.currentFrame.id * 60 * 1000;
      let day = 1440 * 60 * 1000;
      return end > (day - 1000)
        ? this.currentFrame.id * 60 * 1000 - (end - day) - 1000
        : this.currentFrame.id * 60 * 1000;
    },
    onShowMoList(value) {
      if (value) {
        this.onShowMoListStyle = {
          maxHeight: `${this.$refs.mainContainer.clientHeight - 40}px`,
          overflow: 'auto'
        }
      } else {
        this.onShowMoListStyle = null;
      }
    }
  }
}
</script>

<style lang="less">
.eva-video {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  //Главный раздел
  .eva-video__main {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    overflow: hidden;

    //Контейнер с видео
    .eva-video__main-container {
      width: 100%;
      height: 100%;
      position: relative;
      display: flex;
      justify-content: center;

      //Полупрозрачный фон для хедера
      .eva-video__header-background {
        height: 40px;
        width: 100%;
        position: absolute;
        background-color: black;
        opacity: .3;
      }

      .eva-video__header {
        height: 40px;
        width: 100%;
        position: absolute;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 @eva-padding;

        //Отображение и выборк ОМ (слева)
        .eva-video__header-left {
          max-width: 65%;
          color: white;

          .v-btn {
            max-width: 100%;

            .v-btn__content {
              width: 100%;
              justify-content: start;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }

        //Часы и иконки (справа)
        .eva-video__header-right {
          display: flex;
          align-items: center;
          flex-direction: row;
          color: white;

          .eva-video__header-clock {
            text-align: center;
          }
        }
      }

      .eva-video__video {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: black;
      }

      .eva-video__error {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      //Онлайн футер
      .eva-video__footer {
        height: 50px;
        width: 100%;
        position: absolute;
        bottom: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 @eva-padding;
      }
    }

    .eva-video__archive-timeline {
      position: relative;
      height: 21px;

      .timeline-list {
        width: 100%;
        padding: 0 10px;
        display: flex;
        justify-content: space-between;
        position: absolute;

        .timeline-list-div {
          cursor: default;
          -webkit-touch-callout: none; /* iOS Safari */
          -webkit-user-select: none; /* Safari */
          -khtml-user-select: none; /* Konqueror HTML */
          -moz-user-select: none; /* Old versions of Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none; /* Non-prefixed version, currently  supported by Chrome, Edge, Opera and Firefox */
        }
      }

      .timeline-marker {
        width: 2px;
        height: 21px;
        background-color: red;
        position: absolute;
        cursor: pointer;
      }

      //Архивный timeline
      .eva-video__archive-canvas {
        width: 100%;
        height: 21px;
        background-color: black;
      }
    }

    //Архивный футер
    .eva-video__archive-footer {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: @eva-padding;

      .eva-video__archive-footer-left {
        .v-input__slider {
          max-width: 150px;
        }
        .v-input__slot {
          margin: 0;
          .v-slider {
            margin: 0;
            .v-slider__thumb::after {
              width: 32px;
              height: 32px;
            }
            .v-slider__tick {
              border-radius: 50%;
              background-color: #181F27;
              cursor: pointer;
            }
            .v-slider__ticks-container {
              background-color: #181F27;
            }
          }
        }
        .v-messages {
          display: none;
        }
      }

      .eva-video__archive-footer-middle {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: @eva-padding;
      }
    }
  }

  //Боковая панель
  .eva-video__aside-panel {
    width: 140px;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;

    .eva-video__aside-panel-date {
      padding: 0 @eva-padding;

      .v-text-field__details {
        display: none;
      }
    }

    .eva-video__aside-panel-list {
      min-height: 0;
      padding: @eva-padding 0 0;
      .eva-default-table-cell {

        text-align: center;
      }
    }
  }

  video::-webkit-media-controls {
    display: none !important;
  }
}
</style>
