<template>
  <eva-input
      :label="label"
      :placeholder="placeholder"
      :icon="icon"
      :clearable="clearable"
      :readonly="readonly"
      :preview="preview"
      :depth="depth"
      :error="error"
      :class="classes"
      @clear="currentValue = null"
      @click="onClick"
      @icon-click="onIconClick"
  >
    <div class="eva-textbox__input">
      <slot>
        {{ currentValue }}
      </slot>
    </div>
  </eva-input>
</template>

<script>
import EvaInputMixin from "./EvaInputMixin";

export default {
  name: 'eva-popup-input',

  mixins: [
    EvaInputMixin
  ],

  computed: {
    classes() {
      return {
        'eva-cursor-pointer': !this.readonly && !this.preview
      }
    }
  },

  methods: {
    onClick(e) {
      this.$emit('click', e);
    },
    onIconClick(e) {
      this.$emit('icon-click', e);
    }
  }
}
</script>
