<template>
  <eva-textbox
    v-show="!isHide"
    v-model="currentValue"
    :label="fieldLabel"
    :error="fieldErrors"
    :title="fieldTitle"
    :placeholder="fieldPlaceholder"
    :readonly="disabled || readOnly"
    :preview="preview"
    :depth="depth"
    pattern="(-?(\d+)?[.,]?(\d+)?)"
  />
</template>

<script>
export default {
  name: 'eva-float-field',

  dbType: 'float',

  computed: {
    currentValue: {
      get() {
        /*if (typeof this.modelValue === 'number' && this.settings.fraction != null) {
          return this.modelValue.toFixed(this.settings.fraction);
        } else {
          return this.modelValue;
        }*/
        return this.modelValue;
      },
      set(value) {
        this.customError = null;
        if (value != null) {
          if (typeof value !== 'number') {
            value = value.toString().trim();
            if (value) {
              let newValue = this.$eva.$tools.parseFloat(value);
              if (!/^(-?(\d+)?[.,]?(\d+)?)$/.test(value) || isNaN(newValue) || typeof newValue !== 'number') {
                this.customError = 'Введено некорректное дробное число';
              } else {
                value = newValue;
                this.customError = null;
              }
            } else {
              value = null;
            }
          }
        }
        this.modelValue = value;
      }
    }
  }
}
</script>
