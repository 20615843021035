export default {
  props: {
    value: {},
    label: {},
    sublabel: {},
    sublabelColor: {},
    placeholder: {},
    maxlength: {},
    icon: {},
    iconlabel: {},
    readonly: {},
    preview: {},
    depth: {
      type: Number,
      default: 1,
    },
    error: {},
    title: {}
  },

  computed: {
    currentValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    },
    clearable() {
      return (this.value != null && this.value !== '') && !this.readonly && !this.preview;
    }
  }
}
