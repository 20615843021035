<template>
  <eva-layout
      v-if="wrapper"
      row fill no-gap
      :class="$options.name"
      transparent
  >
    <eva-drawer left mini actions hover storage="eva-metadata-designer-item-component__drawer-left" class="eva-background-3">
      <template v-slot:actions>
        <eva-command-list :commands="columnCommands" no-gap type="icon--flat"/>
      </template>
      <eva-layout column transparent style="padding-bottom: 8px; overflow: auto">
        <eva-text header text="Список полей"/>
        <eva-layout
          v-for="(column, columnIndex) in wrapper.columns"
          row
          class="eva-field"
          transparent
          :key="columnIndex"
          :class="{
            'eva-background-5' : column === selectedColumn,
            'eva-field--error' : column.hasError
          }"
          @click="setSelectedColumn(column)"
        >
          <eva-text :text="(columnIndex + 1)" style="flex-shrink: 0; width: 24px; text-align: center"/>
          <eva-icon :icon="column.icon" style="flex-shrink: 0;"/>
          <eva-text :text="column.description || column.name"/>
        </eva-layout>
      </eva-layout>
    </eva-drawer>
    <eva-layout column fill no-gap transparent>

      <v-tabs style="height: 100%">
        <v-tab>Форма</v-tab>
        <v-tab v-if="!short">Таблица</v-tab>
        <v-tab>Структура</v-tab>
        <v-tab-item>
          <eva-layout column scroll transparent class="eva-background-2 h-100" >
            <eva-form
              :settings="currentSettings"
              :model="currentModel"
              :style="formStyle"
              design-mode
              :selected-name="!selectedColumn ? null : selectedColumn.id"
              @selected-name="setSelectedColumn(wrapper.columns.find((c) => c.id === $event))"
              @drop-field="onDropField"
            />
          </eva-layout>
        </v-tab-item>
        <v-tab-item v-if="!short">
          <eva-table
            :settings="currentSettings"
          />
        </v-tab-item>
        <v-tab-item>
          <div v-if="wrapper" style="width: 100%; height: 100%;">
            <pre style="width: 100%; height: 800px; overflow: auto" >{{ model }}</pre>
          </div>
        </v-tab-item>
      </v-tabs>

    </eva-layout>
    <eva-drawer right mini actions hover storage="eva-metadata-designer-item-component__drawer-right" class="eva-background-3">
      <eva-layout column transparent>
        <eva-layout v-show="!selectedColumn" column transparent>
          <eva-text header text="Настройки метаданных"/>
          <eva-form
              :settings="metadataFormSettings"
              :model="wrapper"
              class="eva-overflow"
              ref="metadataForm"
          />
        </eva-layout>
        <eva-layout v-show="!!selectedColumn" column transparent>
          <eva-text header text="Настройки поля"/>
          <eva-form
              :settings="fieldFormSettings"
              :model="selectedColumn"
              class="eva-overflow"
              ref="columnsForm"
          />
        </eva-layout>
      </eva-layout>
    </eva-drawer>
  </eva-layout>
</template>

<script>
import createMetadataWrapper from "./createMetadataWrapper";
import {computed} from "vue";

const NAMES_REGEXP = {
  value: /^[a-z]+[a-z0-9_.]*$/ig,
  message: 'Первым символом должна быть английская буква. Далее допустимы только английские буквы, цифры, точка или нижее подчеркивание.'
}

export default {
  name: 'eva-metadata-designer-item-component',

  props: {
    model: {
      type: Object
    },
    short: {
      type: Boolean,
      default: false
    },
    box: {
      type: Object
    }
  },

  data() {
    return {
      wrapper: null,
      selectedColumn: null,
      currentSettings: null,
      currentModel: null,
      metadataFormSettings: null,
      fieldFormSettings: null,
      columnCommands: this.$eva.$commands.createList([
        {
          name: 'addColumn',
          prefix: `${this.$options.name}.commands`,
          icon: 'mdi-plus',
          error: true,
          handle: () => this.setSelectedColumn(this.wrapper.addColumn())
        },
        {
          name: 'removeColumn',
          prefix: `${this.$options.name}.commands`,
          icon: 'mdi-minus',
          error: true,
          confirm: true,
          handle: () => {
            if (this.selectedColumn) {
              this.setSelectedColumn(this.wrapper.removeColumn(this.selectedColumn));
            }
          }
        },
        {
          name: 'moveUp',
          prefix: `${this.$options.name}.commands`,
          icon: 'mdi-chevron-double-up',
          handle: () => {
            if (this.selectedColumn) {
              this.wrapper.moveUp(this.selectedColumn);
            }
          }
        },
        {
          name: 'moveDown',
          prefix: `${this.$options.name}.commands`,
          icon: 'mdi-chevron-double-down',
          handle: () => {
            if (this.selectedColumn) {
              this.wrapper.moveDown(this.selectedColumn);
            }
          }
        }
      ])
    }
  },

  computed: {
    formStyle() {
      return {
        width: (this.wrapper.width || '600px').replace('%', 'vw'),
        margin: '0 auto'
      }
    }
  },

  watch: {
    model: {
      handler(value) {
        if (value) {
          const currentSettings = this.$eva.$tools.clone(value.params.settings);
          currentSettings.url = '/api/v1/persons';
          currentSettings.type = 'drawer'
          currentSettings.width = '750px'
          currentSettings.readOnly = true;
          this.currentSettings = currentSettings;
          this.$eva.$locales.register('ru', value.params.locales.ru);
          this.currentModel = {};
        }
      },
      deep: true
    },
    selectedColumn(value) {
      this.createFieldFormSettings(value);
      this.setCommandsEnabled();
    },
    'selectedColumn.type'() {
      this.createFieldFormSettings(this.selectedColumn);
    },
    'fieldFormSettings.errors': {
      handler() {
        if (this.selectedColumn) {
          this.selectedColumn.customError = !!(
              this.fieldFormSettings &&
              this.fieldFormSettings.errors &&
              this.fieldFormSettings.errors.length
          );
          this.setCommandsEnabled();
        }
      },
      deep: true
    },
    'wrapper.widthUnit'() {
      if (this.wrapper && !this.wrapper.widthUnit) {
        this.wrapper.widthValue = null;
      }
    }
  },

  methods: {
    onDropField({ name, x, y }) {
      const column = this.wrapper.columns.find((c) => c.name === name);
      if (column) {
        column.x = x;
        column.y = y;
      }
    },
    createMetadataFormSettings() {
      const metadataFormSettings = {
        prefix: `${this.$options.name}.metadataFormSettings`,
        columns: {
          base_settings: {
            type: 'label',
            header: true,
            paddingBottom: true
          },
          service: {
            type: 'text',
            readOnly: true,
          },
          object: {
            type: 'text',
            readOnly: true,
          },
          name: {
            type: 'text',
            readOnly: true,
          },
          variant: {
            type: 'select',
            readOnly: true,
            return: 'id',
            items: [{
              id: 'system',
              label: 'Системный'
            },{
              id: 'new',
              label: 'Новый'
            },{
              id: 'inherit',
              label: 'На основе существующего'
            }]
          },
          description: {
            type: 'text'
          },
          form_settings: {
            type: 'label',
            header: true,
            paddingTop: true,
            paddingBottom: true
          },
          widthValue: {
            type: 'number',
            validatorsTrigger: 'widthUnit',
            disabled: computed(() => !this.wrapper || !this.wrapper.widthUnit),
            validators:  {
              require: {},
              switch: {
                'widthUnit': {
                  '%': [{
                    type: 'range',
                    min: 1,
                    max: 100
                  }],
                  'px': [{
                    type: 'range',
                    min: 600,
                    max: 3840
                  }]
                }
              }
            }
          },
          widthUnit: {
            type: 'select',
            items: [{
              id: 'px',
              label: 'px'
            },{
              id: '%',
              label: '%'
            }],
            return: 'id',
            validators: {
              require: {}
            }
          },
          w: {
            type: 'number',
            validators: {
              require: {},
              range: {
                min: 1,
                max: 12
              }
            }
          },
          h: {
            type: 'number',
            validators: {
              require: {},
              range: {
                min: 1,
                max: 100
              }
            }
          }
        },
        layouts: {
          main: {
            type: 'grid',
            w: 2,
            fields: {
              base_settings: { x: 0, y: 0, w: 2, h: 1 },
              service:       { x: 0, y: 1, w: 2, h: 1 },
              object:        { x: 0, y: 2, w: 2, h: 1 },
              name:          { x: 0, y: 3, w: 2, h: 1 },
              variant:       { x: 0, y: 4, w: 2, h: 1 },
              description:   { x: 0, y: 5, w: 2, h: 1 },
              form_settings: { x: 0, y: 6, w: 2, h: 1 },
              widthValue:    { x: 0, y: 7, w: 1, h: 1 },
              widthUnit:     { x: 1, y: 7, w: 1, h: 1 },
              w:             { x: 0, y: 8, w: 1, h: 1 },
              h:             { x: 1, y: 8, w: 1, h: 1 }
            }
          }
        }
      }
      if (this.wrapper.variant !== 'inherit') {
        delete metadataFormSettings.columns.object;
      }
      this.metadataFormSettings = metadataFormSettings;
    },
    async createFieldFormSettings(value) {
      await this.validateColumn();
      if (value) {
        const typeItems = computed(() => {
          let result = [];
          if (this.selectedColumn) {
            switch (this.selectedColumn.attrs_type) {
              case 'bool':
                result.push({ id: 'checkbox', label: 'Флаг' });
                break;
              case 'int':
                result.push({ id: 'number', label: 'Число' });
                result.push({ id: 'date', label: 'Дата' });
                result.push({ id: 'datetime', label: 'Дата и время' });
                break;
              case 'float':
                result.push({ id: 'float', label: 'Дробное число' });
                break;
              case 'str':
                result.push({ id: 'label', label: 'Заголовок' });
                result.push({ id: 'text', label: 'Текст' });
                result.push({ id: 'text-area', label: 'Многострочный текст' });
                result.push({ id: 'radio-button', label: 'Список переключателей' });
                result.push({ id: 'select', label: 'Простое поле выбора' });
                result.push({ id: 'command', label: 'Команда' });
                break;
              case 'dict':
                result.push({ id: 'jsonarea', label: 'Редактор json' });
                break;
              case 'ref':
                result.push({ id: 'catalog-ref', label: 'Поле выбора' });
                result.push({ id: 'image', label: 'Изображение' });
                result.push({ id: 'select-file', label: 'Файл' });
                break;
              case 'ref_list':
                result.push({ id: 'catalog-ref', label: 'Поле выбора' });
                result.push({ id: 'file', label: 'Список файлов' });
                break;
            }
            if (!result.find((r) => r.id === this.selectedColumn.type)) {
              this.selectedColumn.type = result[0] && result[0].id;
            }
          }
          return result;
        })
        const fieldFormSettings = {
          errors: null,
          prefix: `${this.$options.name}.fieldFormSettings`,
          columns: {
            name: {
              type: 'text',
              error: computed(() => value.nameError),
              readOnly: value.isSystem,
              validators: {
                regexp: NAMES_REGEXP
              }
            },
            description: {
              type: 'text'
            },
            attrs_type: {
              type: 'select',
              return: 'id',
              validators: [
                'require'
              ],
              items: [{
                id: 'bool',
                label: 'Логическое значение'
              },{
                id: 'int',
                label: 'Число'
              },{
                id: 'float',
                label: 'Дробное число'
              },{
                id: 'str',
                label: 'Строка'
              },{
                id: 'dict',
                label: 'JSON'
              },{
                id: 'ref',
                label: 'Ссылка на объект'
              },{
                id: 'ref_list',
                label: 'Список ссылок на объект'
              }],
              readOnly: value.isSystem
            },
            type: {
              type: 'select',
              return: 'id',
              validators: [
                'require'
              ],
              items: typeItems
            },
            x: {
              type: 'number'
            },
            y: {
              type: 'number'
            },
            w: {
              type: 'number'
            },
            h: {
              type: 'number'
            }
          },
          layouts: {
            main: {
              type: 'grid',
              w: 2,
              fields: {
                name:           { x: 0, y: 0, w: 2, h: 1 },
                description:    { x: 0, y: 1, w: 2, h: 1 },
                attrs_type:     { x: 0, y: 2, w: 2, h: 1 },
                type:           { x: 0, y: 3, w: 2, h: 1 },
                readOnly:       { x: 0, y: 4, w: 2, h: 1 },
                x:              { x: 0, y: 5, w: 1, h: 1 },
                y:              { x: 1, y: 5, w: 1, h: 1 },
                w:              { x: 0, y: 6, w: 1, h: 1 },
                h:              { x: 1, y: 6, w: 1, h: 1 }
              }
            }
          }
        };
        switch (value.type) {
          case 'command': {
            this.createFieldFormSettingsHeader(fieldFormSettings, 'Настройки команды');
            this.createFieldFormColumn(fieldFormSettings, this.selectedColumn, 'columnRaw.command', null, {
              type: 'catalog-ref',
              url: '/api/v1/admin/iagentservice/commandconfiguration',
              validators: [
                'require'
              ]
            });
          }
            break;
          case 'label': {
            this.createFieldFormSettingsHeader(fieldFormSettings, 'Настройки заголовка');
            this.createFieldFormColumn(fieldFormSettings, this.selectedColumn, 'columnRaw.label.type', 'regular', {
              type: 'select',
              items: [
                {id: 'regular', label: 'Обычный текст'},
                {id: 'header', label: 'Увеличенный'}
              ],
              return: 'id'
            });
          }
            break;
          case 'text': {
            this.createFieldFormSettingsHeader(fieldFormSettings, 'Настройки текста');
            this.createFieldFormColumn(fieldFormSettings, this.selectedColumn, 'placeholder', '', {
              type: 'text'
            });
            this.createFieldFormColumn(fieldFormSettings, this.selectedColumn, 'columnRaw.password', false, {
              type: 'checkbox'
            });
            this.createFieldFormColumn(fieldFormSettings, this.selectedColumn, 'validatorRequire', false, {
              type: 'checkbox'
            });
          }
            break;
          case 'text-area': {
            this.createFieldFormSettingsHeader(fieldFormSettings, 'Настройки многострочного текста');
            this.createFieldFormColumn(fieldFormSettings, this.selectedColumn, 'placeholder', '', {
              type: 'text'
            });
            this.createFieldFormColumn(fieldFormSettings, this.selectedColumn, 'validatorRequire', false, {
              type: 'checkbox'
            });
          }
            break;
          case 'number': {
            this.createFieldFormSettingsHeader(fieldFormSettings, 'Настройки числа');
            this.createFieldFormColumn(fieldFormSettings, this.selectedColumn, 'placeholder', '', {
              type: 'text'
            });
            this.createFieldFormColumn(fieldFormSettings, this.selectedColumn, 'validatorRequire', false, {
              type: 'checkbox'
            });
            this.createFieldFormColumn(fieldFormSettings, this.selectedColumn, 'validatorMin', null, {
              type: 'number',
              validatorsTrigger: 'validatorMax',
              validators: {
                custom: {
                  validate: () => this.validateMinMax()
                }
              }
            });
            this.createFieldFormColumn(fieldFormSettings, this.selectedColumn, 'validatorMax', null, {
              type: 'number',
              validatorsTrigger: 'validatorMin',
              validators: {
                custom: {
                  validate: () => this.validateMinMax()
                }
              }
            });
          }
            break;
          case 'float': {
            this.createFieldFormSettingsHeader(fieldFormSettings, 'Настройки дробного числа');
            /*this.createFieldFormColumn(fieldFormSettings, this.selectedColumn, 'columnRaw.fraction', 2, {
              type: 'number',
              validators: {
                range: {
                  min: 0,
                  max: 10
                }
              }
            });*/
            this.createFieldFormColumn(fieldFormSettings, this.selectedColumn, 'placeholder', '', {
              type: 'text'
            });
            this.createFieldFormColumn(fieldFormSettings, this.selectedColumn, 'validatorRequire', false, {
              type: 'checkbox'
            });
            this.createFieldFormColumn(fieldFormSettings, this.selectedColumn, 'validatorMin', null, {
              type: 'float',
              validatorsTrigger: 'validatorMax',
              validators: {
                custom: {
                  validate: () => this.validateMinMax()
                }
              }
            });
            this.createFieldFormColumn(fieldFormSettings, this.selectedColumn, 'validatorMax', null, {
              type: 'float',
              validatorsTrigger: 'validatorMin',
              validators: {
                custom: {
                  validate: () => this.validateMinMax()
                }
              }
            });
          }
            break;
          case 'checkbox': {
            this.createFieldFormSettingsHeader(fieldFormSettings, 'Настройки флага');
            this.createFieldFormColumn(fieldFormSettings, this.selectedColumn, 'title', '', {
              type: 'text'
            });
            this.createFieldFormColumn(fieldFormSettings, this.selectedColumn, 'columnRaw.revert', false, {
              type: 'checkbox'
            });
            this.createFieldFormColumn(fieldFormSettings, this.selectedColumn, 'columnRaw.tristate', false, {
              type: 'checkbox'
            });
            this.createFieldFormColumn(fieldFormSettings, this.selectedColumn, 'validatorRequire', false, {
              type: 'checkbox'
            });
          }
            break;
          case 'date': {
            this.createFieldFormSettingsHeader(fieldFormSettings, 'Настройки даты');
            this.createFieldFormColumn(fieldFormSettings, this.selectedColumn, 'placeholder', '', {
              type: 'text'
            });
            this.createFieldFormColumn(fieldFormSettings, this.selectedColumn, 'columnRaw.min', null, {
              type: 'date',
              startDate: true,
              validatorsTrigger: 'columnRaw.max',
              validators: {
                custom: {
                  validate: () => this.validateMinMaxDate()
                }
              }
            });
            this.createFieldFormColumn(fieldFormSettings, this.selectedColumn, 'columnRaw.max', null, {
              type: 'date',
              startDate: false,
              validatorsTrigger: 'columnRaw.min',
              validators: {
                custom: {
                  validate: () => this.validateMinMaxDate()
                }
              }
            });
            this.createFieldFormColumn(fieldFormSettings, this.selectedColumn, 'columnRaw.startDate', false, {
              type: 'checkbox',
              startDate: false
            });
            this.createFieldFormColumn(fieldFormSettings, this.selectedColumn, 'validatorRequire', false, {
              type: 'checkbox'
            });
          }
            break;
          case 'datetime': {
            this.createFieldFormSettingsHeader(fieldFormSettings, 'Настройки даты и времени');
            this.createFieldFormColumn(fieldFormSettings, this.selectedColumn, 'placeholder', '', {
              type: 'text'
            });
            this.createFieldFormColumn(fieldFormSettings, this.selectedColumn, 'columnRaw.min', null, {
              type: 'date',
              startDate: true,
              validatorsTrigger: 'columnRaw.max',
              validators: {
                custom: {
                  validate: () => this.validateMinMaxDate()
                }
              }
            });
            this.createFieldFormColumn(fieldFormSettings, this.selectedColumn, 'columnRaw.max', null, {
              type: 'date',
              startDate: false,
              validatorsTrigger: 'columnRaw.min',
              validators: {
                custom: {
                  validate: () => this.validateMinMaxDate()
                }
              }
            });
            this.createFieldFormColumn(fieldFormSettings, this.selectedColumn, 'columnRaw.utc', false, {
              type: 'checkbox'
            });
            this.createFieldFormColumn(fieldFormSettings, this.selectedColumn, 'validatorRequire', false, {
              type: 'checkbox'
            });
          }
            break;
          case 'select': {
            this.createFieldFormSettingsHeader(fieldFormSettings, 'Настройки простого поля выбора');
            this.createFieldFormColumn(fieldFormSettings, this.selectedColumn, 'placeholder', '', {
              type: 'text'
            });
            this.createFieldFormColumn(fieldFormSettings, this.selectedColumn, 'columnRaw.items', false, {
              type: 'table',
              table: {
                type: 'drawer',
                prefix: `${this.$options.name}.selectItemsFormSettings`,
                short: true,
                footer: false,
                caption: false,
                context: false,
                columns: {
                  id: {
                    type: 'text',
                    validators: [
                      'require'
                    ]
                  },
                  label: {
                    type: 'text',
                    validators: [
                      'require'
                    ]
                  }
                },
                commands: true
              }
            });
            /*this.createFieldFormColumn(fieldFormSettings, this.selectedColumn, 'columnRaw.multiple', false, {
              type: 'checkbox'
            });*/
            this.createFieldFormColumn(fieldFormSettings, this.selectedColumn, 'validatorRequire', false, {
              type: 'checkbox'
            });
          }
            break;
          case 'catalog-ref': {
            this.createFieldFormSettingsHeader(fieldFormSettings, 'Настройки поля выбора');
            this.createFieldFormColumn(fieldFormSettings, this.selectedColumn, 'placeholder', '', {
              type: 'text'
            });
            this.createFieldFormColumn(fieldFormSettings, this.selectedColumn, 'type_service', '', {
              type: 'catalog-ref',
              url: '/api/v1/admin/services',
              return: 'name',
              validators: [
                'require'
              ]
            });
            this.createFieldFormColumn(fieldFormSettings, this.selectedColumn, 'service_version', 'v3', {
              type: 'text',
              validators: [
                'require'
              ]
            });
            this.createFieldFormColumn(fieldFormSettings, this.selectedColumn, 'type_object', '', {
              type: 'catalog-ref',
              url: '/api/v1/admin/metadata',
              return: 'meta_name',
              path: 'meta_name',
              q: [{
                field: 'type_service',
                op: '__eq__',
                value: '$current.type_service'
              }],
              validators: [
                'require'
              ]
            });
            this.createFieldFormColumn(fieldFormSettings, this.selectedColumn, 'columnRaw.url', '', {
              type: 'catalog-ref',
              url: '/api/v1/core/routes',
              onLoad: (items) => items.forEach((item) => item.name = (item.name || '').replaceAll('/', ' / ')),
              return: 'id',
              validators: [
                'require'
              ]
            });
            this.createFieldFormColumn(fieldFormSettings, this.selectedColumn, 'columnRaw.q', '', {
              type: 'text'
            });
            this.createFieldFormColumn(fieldFormSettings, this.selectedColumn, 'validatorRequire', false, {
              type: 'checkbox'
            });
          }
            break;
          case 'select-file': {
            this.createFieldFormSettingsHeader(fieldFormSettings, 'Настройки файла');
            this.createFieldFormColumn(fieldFormSettings, this.selectedColumn, 'placeholder', '', {
              type: 'text'
            });
            this.createFieldFormColumn(fieldFormSettings, this.selectedColumn, 'type_service', '', {
              type: 'catalog-ref',
              url: '/api/v1/admin/services',
              return: 'name',
              validators: [
                'require'
              ]
            });
            this.createFieldFormColumn(fieldFormSettings, this.selectedColumn, 'service_version', 'v3', {
              type: 'text',
              validators: [
                'require'
              ]
            });
            this.createFieldFormColumn(fieldFormSettings, this.selectedColumn, 'type_object', '', {
              type: 'catalog-ref',
              url: '/api/v1/admin/metadata',
              return: 'meta_name',
              path: 'meta_name',
              q: [{
                field: 'type_service',
                op: '__eq__',
                value: '$current.type_service'
              }],
              validators: [
                'require'
              ]
            });
            this.createFieldFormColumn(fieldFormSettings, this.selectedColumn, 'validatorRequire', false, {
              type: 'checkbox'
            });
          }
            break;
          case 'radio-button': {
            this.createFieldFormSettingsHeader(fieldFormSettings, 'Настройки списка переключателей');
            this.createFieldFormColumn(fieldFormSettings, this.selectedColumn, 'placeholder', '', {
              type: 'text'
            });
            this.createFieldFormColumn(fieldFormSettings, this.selectedColumn, 'columnRaw.items', false, {
              type: 'table',
              table: {
                type: 'drawer',
                prefix: `${this.$options.name}.selectItemsFormSettings`,
                short: true,
                footer: false,
                caption: false,
                context: false,
                columns: {
                  id: {
                    type: 'text'
                  },
                  label: {
                    type: 'text'
                  }
                },
                commands: true
              }
            });
            this.createFieldFormColumn(fieldFormSettings, this.selectedColumn, 'validatorRequire', false, {
              type: 'checkbox'
            });
          }
            break;
          case 'file': {
            this.createFieldFormSettingsHeader(fieldFormSettings, 'Настройки файла');
            this.createFieldFormColumn(fieldFormSettings, this.selectedColumn, 'type_service', '', {
              type: 'catalog-ref',
              url: '/api/v1/admin/services',
              return: 'name',
              validators: [
                'require'
              ]
            });
            this.createFieldFormColumn(fieldFormSettings, this.selectedColumn, 'service_version', 'v3', {
              type: 'text',
              validators: [
                'require'
              ]
            });
            this.createFieldFormColumn(fieldFormSettings, this.selectedColumn, 'type_object', '', {
              type: 'catalog-ref',
              url: '/api/v1/admin/metadata',
              return: 'meta_name',
              path: 'meta_name',
              q: [{
                field: 'type_service',
                op: '__eq__',
                value: '$current.type_service'
              }],
              validators: [
                'require'
              ]
            });
          }
            break;
          case 'image': {
            this.createFieldFormSettingsHeader(fieldFormSettings, 'Настройки изображения');
            this.createFieldFormColumn(fieldFormSettings, this.selectedColumn, 'type_service', '', {
              type: 'catalog-ref',
              url: '/api/v1/admin/services',
              return: 'name',
              validators: [
                'require'
              ]
            });
            this.createFieldFormColumn(fieldFormSettings, this.selectedColumn, 'service_version', 'v3', {
              type: 'text',
              validators: [
                'require'
              ]
            });
            this.createFieldFormColumn(fieldFormSettings, this.selectedColumn, 'type_object', '', {
              type: 'catalog-ref',
              url: '/api/v1/admin/metadata',
              return: 'meta_name',
              path: 'meta_name',
              q: [{
                field: 'type_service',
                op: '__eq__',
                value: '$current.type_service'
              }],
              validators: [
                'require'
              ]
            });
          }
            break;
        }
        this.fieldFormSettings = fieldFormSettings;
        this.$nextTick(() => this.validateColumn());
      } else {
        this.fieldFormSettings = null;
      }
    },
    createFieldFormSettingsHeader(settings, header) {
      settings.columns['columnRaw.header'] = {
        type: 'label',
        label: header
      }
    },
    createFieldFormColumn(settings, model, name, defaultValue, value) {
      settings.columns[name] = value;
      let parts = name.split('.');
      let find = true;
      let cur = model;
      for (let i = 0; i < parts.length; i++) {
        cur = cur[parts[i]];
        if (cur == null) {
          find = false;
          break;
        }
      }
      if (!find) {
        this.$eva.$tools.setNestedValue(model, name, defaultValue, (o, k, v) => this.$set(o, k, v));
      }
    },
    setSelectedColumn(column) {
      if (this.selectedColumn === column) {
        this.selectedColumn = null;
      } else {
        this.selectedColumn = null;
        this.$nextTick(() => this.selectedColumn = column);
      }
    },
    setCommandsEnabled() {
      const notSelected = !this.selectedColumn;
      const hasError = this.selectedColumn && this.selectedColumn.hasError;
      this.columnCommands[0].disabled = hasError;
      this.columnCommands[1].disabled = notSelected || hasError;
      this.columnCommands[2].disabled = notSelected || hasError;
      this.columnCommands[3].disabled = notSelected || hasError;
    },
    async validateColumn() {
      if (this.$refs.columnsForm && this.selectedColumn) {
        let columns = await this.$refs.columnsForm.validate();
        this.selectedColumn.customError = !!(columns && columns.length);
      }
    },
    async validate() {
      if (await this.$refs.metadataForm.validate()) {
        return true;
      }
      await this.validateColumn();
      if (this.wrapper && this.wrapper.columns.find((c) => c.hasError)){
        return true;
      }
    },
    validateMinMax() {
      if (
        this.selectedColumn &&
        this.selectedColumn.validatorMin !== null &&
        this.selectedColumn.validatorMax != null &&
        this.selectedColumn.validatorMin > this.selectedColumn.validatorMax
      ) {
        return this.$eva.$t(`$t.${this.$options.name}.fieldFormSettings.errors.minMoreMax`);
      } else {
        return null;
      }
    },
    validateMinMaxDate() {
      if (
        this.selectedColumn &&
        this.selectedColumn.columnRaw &&
        this.selectedColumn.columnRaw.min !== null &&
        this.selectedColumn.columnRaw.max != null &&
        this.selectedColumn.columnRaw.min > this.selectedColumn.columnRaw.max
      ) {
        return this.$eva.$t(`$t.${this.$options.name}.fieldFormSettings.errors.minMoreMax`);
      } else {
        return null;
      }
    }
  },

  async mounted() {
    if (!this.model.id) {
      this.$nextTick(async () => {
        let model = {
          service: '',
          object: '',
          meta_name: ''
        };
        let res = await this.$eva.$boxes.showForm({
          type: 'dialog',
          header: 'Основные настройки',
          settings: {
            prefix: `${this.$options.name}.metadataFormSettings`,
            columns: this.model.state.id === 'new' ? {
              service: {
                type: 'catalog-ref',
                url: '/api/v1/admin/services',
                return: 'name',
                validators: ['require']
              },
              meta_name: {
                type: 'text',
                validators: {
                  require: {},
                  unique: {
                    url: '/api/v1/admin/metadata'
                  },
                  regexp: NAMES_REGEXP
                }
              }
            } : {
              service: {
                type: 'catalog-ref',
                url: '/api/v1/admin/services',
                return: 'name',
                validators: ['require']
              },
              object: {
                type: 'catalog-ref',
                url: '/api/v1/admin/metadata',
                return: 'meta_name',
                path: 'meta_name',
                q: [{
                  field: 'type_service',
                  op: '__eq__',
                  value: '$current.service'
                },{
                  field: 'system_object_flag',
                  op: '__eq__',
                  value: true
                }],
                validators: ['require']
              },
              meta_name: {
                type: 'text',
                validators: {
                  require: {},
                  unique: {
                    url: '/api/v1/admin/metadata'
                  },
                  regexp: NAMES_REGEXP
                }
              }
            }
          },
          model,
          ok: (...args) => {}
        })
        if (res !== 'ok') {
          this.box.hide('cancel');
        } else {
          this.$set(this.model, 'system_object_flag', false);
          this.$set(this.model, 'role_search', {});
          this.$set(this.model, 'fields', {});
          this.$set(this.model, 'params', {});
          if (this.model.state.id === 'new') {
            this.wrapper = createMetadataWrapper(this.model, this.$eva);
            this.wrapper.service = model.service;
            this.wrapper.object = model.meta_name;
            this.wrapper.name = model.meta_name;
            this.wrapper.variant = 'new';
          } else {
            const meta = await this.$eva.$http.getListFirstItem(`/api/v1/metadata?q=meta_name__eq__${model.object}`);
            delete meta.fields.ref_metadata;
            delete meta.fields.subsystemData;
            if (model.object === 'PassportObject') {
              this.$set(this.model, 'fields', {});
              this.$set(this.model, 'params', {});
            } else {
              this.$set(this.model, 'fields', meta.fields);
              this.$set(this.model, 'params', meta.params);
            }
            this.wrapper = createMetadataWrapper(this.model, this.$eva);
            this.wrapper.service = model.service;
            this.wrapper.object = model.object;
            this.wrapper.name = model.meta_name;
            this.wrapper.variant = 'inherit';
          }
          this.createMetadataFormSettings();
        }
      });
    } else {
      this.$set(this.model, 'fields', this.model.fields || {});
      this.$set(this.model, 'params', this.model.params || {});
      this.wrapper = createMetadataWrapper(this.model, this.$eva);
      this.createMetadataFormSettings();
    }
    this.setCommandsEnabled();
  }
}
</script>

<style lang="less">
.eva-metadata-designer-item-component {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: @eva-padding;
  .eva-text--header {
    padding: 0 @eva-padding @eva-padding;
  }
  .eva-overflow {
    height: 100%;
    overflow: auto;
    min-height: 0;
  }
  .eva-field {
    /*color: #3E4C5D;*/
    padding: 0 @eva-padding;
    display: flex;
    align-items: center;
    flex-shrink: 0;
    &.eva-field--even {
      /*background-color: #F6F8FF;*/
    }
    &.eva-field--error {
      color: #E23244!important;
      .eva-text {
        color: #E23244!important;
      }
    }
    &:hover {
      /*background-color: #E1F5FE;*/
      cursor: pointer;
    }
  }

  .v-window {
    flex-grow: 1;
    display: flex;
    height: calc(100% - 48px);
    .v-window__container {
      height: 100%;
      width: 100%;
      .v-window-item {
        height: 100%;
      }
    }
  }
}
</style>

<locale lang="ru">
{
  commands: {
    addColumn: {
      header: 'Добавить поле'
    },
    removeColumn: {
      header: 'Удалить поле',
      confirm: 'Удалить поле ?'
    },
    moveUp: {
      header: 'Переместить выше'
    },
    moveDown: {
      header: 'Переместить ниже'
    }
  },
  metadataFormSettings: {
    columns: {
      base_settings: {
        header: 'Основные'
      },
      service: {
        header: 'Сервис'
      },
      object: {
        header: 'Базовый класс'
      },
      name: {
        header: 'Новый класс'
      },
      meta_name: {
        header: 'Новый класс'
      },
      description: {
        header: 'Описние'
      },
      variant: {
        header: 'Тип класса',
      },
      form_settings: {
        header: 'Форма'
      },
      widthValue: {
        header: 'Ширина'
      },
      widthUnit: {
        header: 'Единицы'
      },
      w: {
        header: 'Столбцы'
      },
      h: {
        header: 'Строки'
      }
    }
  },
  fieldFormSettings: {
    columns: {
      name: {
        header: 'Название'
      },
      description: {
        header: 'Описание'
      },
      attrs_type: {
        header: 'Тип данных'
      },
      type: {
        header: 'Тип визуального компонента'
      },
      show_flag: {
        header: 'Показывать в объекте'
      },
      type_service: {
        header: 'Сервис'
      },
      service_version: {
        header: 'Версия сервиса'
      },
      type_object: {
        header: 'Класс'
      },
      showInRepeater: {
        header: 'Показывать в таблице'
      },
      x: {
        header: 'X'
      },
      y: {
        header: 'Y'
      },
      w: {
        header: 'Ширина'
      },
      h: {
        header: 'Высота'
      },
      readOnly: {
        header: 'Только для чтения'
      },
      columnRaw: {
        label: {
          type: {
            header: 'Тип заголовка'
          }
        },
        command: {
          header: 'Команда'
        },
        password: {
          header: 'Пароль'
        },
        revert: {
          header: 'Инвертировать'
        },
        tristate: {
          header: 'Три состояния'
        },
        utc: {
          header: 'Utc'
        },
        min: {
          header: 'Минимальное значение'
        },
        max: {
          header: 'Максимальное значение'
        },
        fraction: {
          header: 'Количество нулей'
        },
        startDate: {
          header: 'Начало дня'
        },
        items: {
          header: 'Элементы списка'
        },
        multiple: {
          header: 'Множественный выбор'
        },
        url: {
          header: 'Url'
        },
        q: {
          header: 'Фильтр Url'
        }
      },
      placeholder: {
        header: 'Подсказка'
      },
      title: {
        header: 'Заголовок'
      },
      validatorRequire: {
        header: 'Обязательное поле'
      },
      validatorMin: {
        header: 'Минимальное значение'
      },
      validatorMax: {
        header: 'Максимальное значение'
      }
    },
    errors: {
      minMoreMax: 'Минимальное значение не может быть больше максимального'
    }
  },
  selectItemsFormSettings: {
    columns: {
      id: {
        header: 'ID'
      },
      label: {
        header: 'Значение'
      }
    },
    commands: {
      add: {
        header: 'Добавить элемент списка',
        success: 'Элемент списка успешно добавлен',
        error: 'Во время добавления элемента списка произошла ошибка'
      },
      edit: {
        header: 'Редактировать элемент списка',
        success: 'Элемент списка отредактирован успешно',
        error: 'При редактировании элемента списка произошла ошибка'
      },
      remove: {
        header: 'Удалить элемент списка',
        confirm: 'Удалить элемент списка?',
        success: 'Элемент списка удален успешно',
        error: 'При удалении элемента списка произошла ошибка'
      }
    }
  }
}
</locale>
