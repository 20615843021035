import EvaPlugin from "@eva/client/plugins/EvaPlugin";
import EvaPage from "./components/layouts/EvaPage";
import EvaLayout from "./components/layouts/EvaLayout";
import EvaGrid from "./components/layouts/EvaGrid";
import EvaSpacer from "./components/layouts/EvaSpacer";
import EvaRowLayout from "./components/layouts/EvaRowLayout";
import EvaColumnLayout from "./components/layouts/EvaColumnLayout";
import EvaHeader from "./components/layouts/EvaHeader";
import EvaDrawer from "./components/layouts/EvaDrawer";
import EvaWidget from "./components/widgets/EvaWidget";
import EvaText from "./components/typography/EvaText";
import EvaDataName from "./components/typography/EvaDataName";
import EvaPre from "./components/typography/EvaPre";
import EvaIcon from "./components/media/EvaIcon";
import EvaImage from "./components/media/EvaImage";
import EvaMediaText from "./components/media/EvaMediaText";
import EvaProgress from "./components/media/EvaProgress";
import EvaVideo from "./components/media/video/EvaVideo";
import EvaList from "./components/repeaters/list/EvaList";
import EvaRepeaterHeader from "./components/repeaters/repeater/EvaRepeaterHeader";
import EvaRepeaterFooter from "./components/repeaters/repeater/EvaRepeaterFooter";
import EvaRepeater from "./components/repeaters/repeater/EvaRepeater";
import EvaTable from "./components/repeaters/table/EvaTable";
import EvaAlert from "./components/messages/EvaAlert";

import EvaTreeNode from "./components/trees/EvaTreeNode";
import EvaTree from "./components/trees/EvaTree";

import EvaTabs from "./components/tabs/EvaTabs";
import EvaTab from "./components/tabs/EvaTab";

import EvaBtn from "./components/buttons/btn";
import EvaBtnCommands from "./components/buttons/btnCommands";
import EvaChip from "./components/buttons/chip";
import EvaInput from "./components/inputs/EvaInput";
import EvaColorInput from "./components/inputs/EvaColorInput";
import EvaPopupInput from "./components/inputs/EvaPopupInput";
import EvaTextbox from "./components/inputs/EvaTextbox";
import EvaSelect from "./components/inputs/EvaSelect";
import EvaCombobox2 from "./components/inputs/EvaCombobox.vue";
import EvaCombobox from "./components/inputs/EvaCombobox.js";
import EvaTextarea from "./components/inputs/EvaTextarea";
import EvaJsonarea from "./components/inputs/EvaJsonarea";
import EvaCheckbox from "./components/inputs/EvaCheckbox";

import EvaMap from "./components/maps/EvaMap";
import EvaMapPreview from "./components/maps/EvaMapPreview";

import EvaFilesTable from "./components/repeaters/tables/EvaFilesTable";

import EvaIntersect from "./components/utilities/EvaIntersect";

import geometry from "./geometry";

class CoreEvaPlugin extends EvaPlugin {

  constructor(app) {
    super(app);
  }

  install() {
    this.app.components(
      EvaPage,
      EvaLayout,
      EvaGrid,
      EvaSpacer,
      EvaRowLayout,
      EvaColumnLayout,
      EvaHeader,
      EvaDrawer,
      EvaWidget,
      EvaIcon,
      EvaText,
      EvaDataName,
      EvaPre,
      EvaImage,
      EvaMediaText,
      EvaProgress,
      EvaVideo,
      EvaList,
      EvaRepeaterHeader,
      EvaRepeaterFooter,
      EvaRepeater,
      EvaTable,
      EvaAlert,
      EvaTreeNode,
      EvaTree,
      EvaTabs,
      EvaTab,
      EvaBtn,
      EvaBtnCommands,
      EvaChip,
      EvaInput,
      EvaColorInput,
      EvaPopupInput,
      EvaTextbox,
      EvaSelect,
      EvaCombobox,
      EvaTextarea,
      EvaJsonarea,
      EvaCheckbox,
      EvaMap,
      EvaMapPreview,
      EvaFilesTable,
      EvaIntersect
    );

    this.app.$geometry = geometry;
  }
}

export default CoreEvaPlugin;
