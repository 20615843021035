const DEFAULT_OPACITY = 0.5;

export default {
  methods: {
    async show({ opacity, activator, anchor } = { }) {
      return new Promise((resolve) => {
        setTimeout(() => {
          this.$el.style.opacity = opacity || DEFAULT_OPACITY;
          if (activator) {
            if (activator.left != null || activator.top != null || activator.right != null || activator.bottom != null) {
              this.$el.style.left = activator.left;
              this.$el.style.top = activator.top;
              this.$el.style.right = activator.right;
              this.$el.style.bottom = activator.bottom;
            } else {
              activator = activator.closest('.eva-btn') || activator;
              let rect = activator.getBoundingClientRect();
              let pageRect = document.body.getBoundingClientRect();
              const elRect = this.$el.getBoundingClientRect();
              const dt = (rect.bottom + elRect.height > pageRect.height) ? rect.bottom + elRect.height - pageRect.height + 16 : 0;
              switch (anchor) {
                case 'bottom-left':
                  this.$el.style.left = `${rect.left}px`;
                  this.$el.style.top = `${rect.bottom - dt}px`;
                  break;
                default:
                  this.$el.style.right = `${(pageRect.right - rect.right)}px`;
                  this.$el.style.top = `${rect.bottom - dt}px`;
                  this.$el.style.left = 'auto';
                  this.$el.style.bottom = 'auto';
                  break;
              }
            }
          }
          this.$eva.$tools.addOnceEventListener(this.$el, 'transitionend', resolve);
        })
      });
    },

    async hide() {
      return new Promise((resolve) => {
        setTimeout(() => {
          this.$el.style.opacity = 0;
          this.$eva.$tools.addOnceEventListener(this.$el, 'transitionend', resolve);
        })
      });
    },
  },

  mounted() {
    this.$el.style.opacity = 0;
    this.$el.style.transition = 'opacity 0.3s';
  }
}
