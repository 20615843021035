<template>
  <div
    v-show="!isHide"
    class="eva-date-field"
  >
    <v-menu
      v-model="menu"
      ref="dateMenu"
      :close-on-content-click="false"
      offset-y
      nudge-top="30px"
      min-width="0"
      :disabled="readOnly || preview"
      style="z-index: 10000"
    >
      <template v-slot:activator="{ on, attrs }">
        <eva-input
          :value="!!modelValue"
          :label="fieldLabel"
          :placeholder="fieldPlaceholder"
          :error="fieldErrors"
          :title="fieldTitle"
          :readonly="readOnly"
          :preview="preview"
          :depth="depth"
          :icon="'mdi-calendar-range'"
          v-bind="attrs"
          v-on="on"
          @icon-click="on.click"
          :clearable="!!modelValue && !readOnly && !preview"
          @clear="clearField"
          @click="$emit('click', $event)"
        >
          <div class="eva-textbox__input">
            {{ currentDate }}
          </div>
        </eva-input>
      </template>
      <v-date-picker
        :value="formattedCalendarDate"
        :locale="$locale.current"
        :min="formattedMinDate"
        :max="formattedMaxDate"
        :first-day-of-week="1"
        no-title
        @change="selectDate"
      />
    </v-menu>
  </div>
</template>

<script>
import { isNil } from 'lodash';
import moment from 'moment';

import { formatDateTime, formatDateToUnix } from '../formatters';

export default {
  name: 'eva-date-field',

  dbType: 'int',

  data() {
    return {
      menu: false,
      currentDate: '', // нужен для поддержки реактивности при вводе значений
      calendarFormat: 'YYYY-MM-DD',

      format: 'DD.MM.YYYY',
      mask: '##.##.####',
      maskChecker: /^\d\d.\d\d.\d\d\d\d$/
    };
  },

  watch: {
    modelValue: {
      handler() {
        this.setValue();
      }
    }
  },

  computed: {
    startDate() {
      return this.settings?.startDate !== false;
    },
    min() {
      const min = this.settings?.min;
      const lowestMin = moment("01-01-1900", "DD-MM-YYYY").valueOf();
      if (!min) {
        return lowestMin;
      }
      if (typeof min === 'string' && min.startsWith('$current')) {
        return Date.now();
      } else {
        return min > lowestMin ? min : lowestMin;
      }

    },
    max() {
      const max = this.settings?.max;
      const highestMax = moment("01-01-2100", "DD-MM-YYYY").valueOf();
      if (!max) {
        return highestMax;
      }
      if (typeof max === 'string' && max.startsWith('$current')) {
        return Date.now();
      } else {
        return max < highestMax ? max : highestMax;
      }
    },
    formattedCalendarDate() {
      // todo: use entered date?
      const value = parseInt(this.modelValue);
      const date =
        !isNil(value) && moment.utc(value).isValid()
          ? moment.utc(value, 'x')
          : moment.utc();
      return formatDateTime(date, this.calendarFormat);
    },
    formattedMinDate() {
      return this.formatEnteredDate(this.min);
    },
    formattedMaxDate() {
      return this.formatEnteredDate(this.max);
    }
  },

  mounted() {
    this.setValue();
  },

  methods: {
    // todo: rename or remove
    formatEnteredDate(newDate) {
      if (isNil(newDate)) {
        return;
      }
      const date =
        moment.utc(parseInt(newDate)).isValid()
          ? moment.utc(parseInt(newDate), 'x')
          : undefined;
      if (isNil(date)) {
        return;
      }
      return formatDateTime(date, this.calendarFormat);
    },
    selectDate(date) {
      this.setModelValue(date, this.calendarFormat);
      this.currentDate = formatDateTime(this.modelValue, this.format);
      this.$emit('changed', this.modelValue);
      this.menu = false;
    },
    changeDate() {
      let date = this.currentDate;
      const isMatchingMask = this.maskChecker.test(date);
      if (isNil(date) || !moment.utc(date, this.format).isValid() || !isMatchingMask) {
        this.clearField();
        return;
      }

      const formattedDate = formatDateToUnix(moment.utc(date, this.format));
      if (formattedDate < this.min || !isNil(this.max) && formattedDate > this.max) {
        this.clearField();
        return;
      }

      this.setModelValue(date, this.format);
      this.currentDate = formatDateTime(formattedDate, this.format);
      this.$emit('changed', this.modelValue);
    },
    setValue() {
      const value = this.modelValue;
      this.currentDate = value ? formatDateTime(value, this.format) : '';
    },
    clearField() {
      this.modelValue = null;
      this.currentDate = '';
      this.$emit('changed', null);
    },
    setModelValue(value, format) {
      if (this.startDate) {
        this.modelValue = formatDateToUnix(moment.utc(
          `${value} 00:00:00`,
          `${format} HH:mm:ss`
        ));
      } else {
        this.modelValue = formatDateToUnix(moment.utc(
            `${value} 23:59:59`,
            `${format} HH:mm:ss`
        ))
      }
    }
  },
}
</script>

<style lang="less">
.eva-date-field {
  position: relative;
  flex: 1;
  
  .v-input__slot {
    background-color: #E5F1FF!important;
  }
}
</style>
