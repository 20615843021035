<template>
  <div class="eva-label-field" :class="mainClasses">
    <span :class="textClasses">{{ fieldLabel }}</span>
    <span :style="valueStyle">{{ currentValue }}</span>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: 'eva-label-field',

  dbType: 'str',

  computed: {
    mainClasses() {
      let result = this.paddingClasses;
      if (!result) {
        result = [];
      }
      if (this.clipped) {
        result.push('eva-text-clipped');
      } else {
        result.push('eva-label-field--center');
      }
      return result;
    },
    textClasses() {
      return {
        'eva-label-field--header': this.settings.label && this.settings.label.type === 'header'
      }
    },
    currentValue() {
      let value = this.modelValue;
      if (value) {
        if (this.settings.path) {
          value = this.$eva.$tools.getNestedValue(value, this.settings.path);
        }
        let format = this.settings.label && this.settings.label.format;
        switch (format) {
          case 'date':
            return moment(value, 'x').format(this.format || 'DD.MM.YYYY');
          case 'datetime':
            return moment(value, 'x').format(this.format || 'DD.MM.YYYY HH:mm');
        }
      }
      return value;
    },
    valueStyle() {
      if (typeof this.settings.color === 'string') {
        return {
          color: this.$eva.$tools.getNestedValue(this.model, this.settings.color)
        };
      }
    },
    clipped() {
      return this.settings && this.settings.clipped !== false;
    }
  }
}
</script>

<style lang="less">
.eva-label-field {
  margin: 4px 0;
  min-width: 0;
  display: flex;
  align-items: center;
  gap: @eva-padding;
  &.eva-label-field--center {
    align-self: center;
  }
  span:first-child {
    /*color: #757575;*/
  }
  .eva-label-field--header {
    font-size: 1.2rem;
  }
}
</style>
