<template>
  <div
    class="eva-tabs"
    :class="{
      'eva-tabs--column': column,
    }"
  >
    <div
      class="eva-tabs__header"
      :class="{
        'eva-tabs__header--column': column,
        'eva-background-1': !column,
      }"
      :style="getTabsStyle"
    >
      <div
          v-for="tab in state.tabs"
          :key="tab.id"
          class="eva-tabs__header-item"
          :class="{
            'eva-tabs__header-item--active': state.current === tab.id && !column,
            'eva-tabs__header-item--active-column': state.current === tab.id && column,
            'eva-background-1': state.current === tab.id && column,
            'eva-tabs__header-item--column': column,
          }"
          @click="onTabClick(tab)"
      >
        <eva-icon v-if="tab.icon" :icon="tab.icon" :color="tab.iconColor"/>
        <eva-text header clipped :text="tab.header"/>
      </div>
    </div>
    <div class="eva-tabs__content">
      <slot/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'eva-tabs',

  provide() {
    return {
      state: this.state
    };
  },

  props: {
    column: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      state: {
        current: null,
        tabs: []
      }
    }
  },

  computed: {
    getTabsStyle() {
      return this.width ? `width: ${this.width};` : '';
    },
  },

  watch: {
    'state.tabs': {
      handler() {
        if (this.state.tabs && this.state.tabs.length) {
          if (!this.state.tabs.find((t) => t.id === this.state.current)) {
            this.state.current = this.state.tabs[0].id;
          }
        } else {
          this.state.current = null;
        }
      },
      deep: true
    }
  },

  methods: {
    onTabClick(tab) {
      this.state.current = tab.id;
    }
  }
}
</script>

<style lang="less">
.eva-tabs {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  &--column {
    flex-direction: row;
  }

  .eva-tabs__header {
    height: @eva-header;
    width: 100%;
    display: flex;
    flex-direction: row;

    &--column {
      flex-direction: column;

      & .eva-tabs__header-item {
        padding: 29px 20px !important;
      }
    }

    .eva-tabs__header-item {
      cursor: pointer;
      font-size: 16px;
      display: flex;
      color: #ACBBCB;
      align-items: center;
      padding: (@eva-padding * 2);
      border-bottom: 2px solid transparent;
      &:hover {
        border-color: #0260CF50;
      }
      &--column {
        &:hover {
          border-color: #3E4C5D;
        }
      }
      &.eva-tabs__header-item--active {
        border-color: #0260CF;
        color: #FFFFFF !important;
      }
      &.eva-tabs__header-item--active-column {
        border-color: #3E4C5D;
        color: #FFFFFF !important;
      }
    }
  }
  .eva-tabs__content {
    min-height: 0;
    height: 100%;
    width: 100%;
  }
}
</style>
