var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"eva-tabs",class:{
    'eva-tabs--column': _vm.column,
  }},[_c('div',{staticClass:"eva-tabs__header",class:{
      'eva-tabs__header--column': _vm.column,
      'eva-background-1': !_vm.column,
    },style:(_vm.getTabsStyle)},_vm._l((_vm.state.tabs),function(tab){return _c('div',{key:tab.id,staticClass:"eva-tabs__header-item",class:{
          'eva-tabs__header-item--active': _vm.state.current === tab.id && !_vm.column,
          'eva-tabs__header-item--active-column': _vm.state.current === tab.id && _vm.column,
          'eva-background-1': _vm.state.current === tab.id && _vm.column,
          'eva-tabs__header-item--column': _vm.column,
        },on:{"click":function($event){return _vm.onTabClick(tab)}}},[(tab.icon)?_c('eva-icon',{attrs:{"icon":tab.icon,"color":tab.iconColor}}):_vm._e(),_vm._v(" "),_c('eva-text',{attrs:{"header":"","clipped":"","text":tab.header}})],1)}),0),_vm._v(" "),_c('div',{staticClass:"eva-tabs__content"},[_vm._t("default")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }